import React, { Component } from "react";
import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";
import { SaveEditQuestion } from "./../../services";
import MSG, {
  notifySuccess,
  notifyError,
  notifyInfo,
  randomKey,
} from "./../../utils/Helper";
import { startLoader, stopLoader } from "./../../store/reducers/generalActions";
// import { trackEvent } from "../../utils/mixpanel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import _ from "lodash";

class Editmediummcq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: this.props.data,
      alert: null,
      mainQuestion: this.props.data.question[0].substring(
        7,
        this.props.data.question[0].length
      ),
      titleofQuestion_emsg: "",
      option_emsg: "",
      selectedOption: null,
      isEmptyIndex: null,
      optionIndex: null,
      isOptionEmpty: null,
      neutralize: false,
      orgfilename: this.props.data.orgfilename
        ? this.props.data.orgfilename
        : null,
      formData_out: null,
      optionIndexes: ["1", "2", "3", "4"],
    };
  }

  async componentDidMount() {
    var optionObj = this.props.data.options;
    let updatedOptionIndexes = [...this.state.optionIndexes];
    optionObj.map((option, index) => {
      if (option.endsWith("*")) {
        updatedOptionIndexes[index] += " *";
        this.setState({
          optionIndex: index,
        });
      }
    });

    this.setState({
      optionIndexes: updatedOptionIndexes,
    });

    if (this.props.disable) {
      this.setState({
        neutralize: !this.state.neutralize,
      });
    }
  }

  handleClickOutside = (evt) => {
    this.props.showalert(this.state.question, this.state.optionIndex);
  };

  handleChange = (e) => {
    let index = e.target.name;
    let changedquestion = { ...this.state.question };
    var questions = [...changedquestion.question];
    questions[index] = "Ques  : " + e.target.value;
    changedquestion.question = questions;
    this.setState({
      question: changedquestion,
      titleofQuestion_emsg: "",
      isEmptyIndex: null,
    });
  };

  handleChangedValue = (e) => {
    // this.setState({
    //   titleofQuestion_emsg: "",
    //   isEmptyIndex: null,
    // });
    let index = e.target.name;
    let changedquestion = { ...this.state.question };
    var questions = [...changedquestion.question];
    questions[index] = e.target.value;
    changedquestion.question = questions;
    this.setState({
      question: changedquestion,
      titleofQuestion_emsg: "",
      isEmptyIndex: null,
    });
  };

  optionchange = (e) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });
    let index = e.target.name;
    let changedquestion = { ...this.state.question };
    var options = [...changedquestion.options];
    var val = options[index];
    if (val.endsWith("*")) {
      options[index] = e.target.value + " *";
    } else {
      options[index] = e.target.value;
    }
    changedquestion.options = options;
    this.setState({
      question: changedquestion,
    });
  };

  radioButtonValue = (event) => {
    this.setState({
      optionIndex: parseInt(event.target.name),
      selectedOption: event.target.value,
    });
  };

  checkBoxButtonValue = (e) => {
    let index = parseInt(e.target.name);
    let updatedOptionIndexes = [...this.state.optionIndexes];
    let val = updatedOptionIndexes[index];

    if (val.endsWith("*")) {
      updatedOptionIndexes[index] = updatedOptionIndexes[index].slice(0, -2);
    } else {
      updatedOptionIndexes[index] += " *";
    }

    this.setState({
      optionIndexes: updatedOptionIndexes,
    });
  };

  validatemainquestion = () => {
    var que = this.state.question.question;
    var question = que.map((val) => val.replace("Ques  :", ""));

    for (var i = 0; i < question.length; i++) {
      if (question[i].trim() === "") {
        this.setState({
          isEmptyIndex: i,
          titleofQuestion_emsg: "Please enter text in the input field.",
        });
        return false;
      }
      if (i === 0 && !question[i].endsWith("?")) {
        this.setState({
          titleofQuestion_emsg: "Please put ? in the end",
        });
        return false;
      }
    }

    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  validateoption = () => {
    var opt = this.state.question.options;
    var option = opt.map((val) => val.replace("*", ""));

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() === "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
    }

    this.setState({
      option_emsg: "",
    });
    return true;
  };

  savechanges = async (e) => {
    e.preventDefault();
    if (!this.validatemainquestion() || !this.validateoption()) {
      return false;
    }
    const changedquestion = { ...this.state.question };

    // option changes
    // if (this.state.isMultipleSelected) {
    // checkboxes
    let optionIndexes = this.state.optionIndexes;
    let count = 0;
    for (let i = 0; i < optionIndexes.length; i++) {
      if (optionIndexes[i].trim().endsWith(" *")) {
        count += 1;
      }
    }

    if (count === 0) {
      this.setState({
        option_emsg: "Please mark atleaset 1 correct answer.",
      });
      return false;
    }

    let optionsObj = changedquestion.options;
    let option = optionsObj.map((val) => val.replace("*", ""));

    for (let i = 0; i < optionIndexes.length; i++) {
      if (optionIndexes[i].trim().endsWith(" *")) {
        option[i] += " *";
      }
    }
    changedquestion.options = option;
    // } else {
    // 	// radio buttons
    // 	if (this.state.optionIndex !== null) {
    // 		var optionsObj = changedquestion.options;
    // 		var opt = optionsObj.map(val => val.replace("*", ""));
    // 		opt[this.state.optionIndex] = opt[this.state.optionIndex] + " *";
    // 		changedquestion.options = opt;
    // 	}
    // }

    if (this.state.neutralize) {
      this.props.handleQuestionUpdate(changedquestion);
      this.props.edit(e);
      return;
    }

    var que = changedquestion.question;
    var opt = changedquestion.options;
    var queId = changedquestion.question_id;

    this.props.dispatch(startLoader());
    try {
      let formData = new FormData();
      formData.append("questionId", queId);
      formData.append("questions", JSON.stringify(que));
      formData.append("options", JSON.stringify(opt));
      formData.append("image_data_out", this.state.formData_out);
      formData.append("image_data_in", this.state.formData_in);
      formData.append("remove_image", this.state.remove_image);

      const { data: res } = await SaveEditQuestion(formData);
      if (res.success === true) {
        notifySuccess(res.message);
        changedquestion.image_data = res.response.image_data;
        if (this.state.remove_image) {
          this.setState({ orgfilename: null });
          changedquestion.orgfilename = null;
        }
        this.props.handleQuestionUpdate(changedquestion);
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
    this.props.edit(e);
  };

  handlequestion = (e) => {
    var index = parseInt(e.target.name) + 1;
    var changedquestion = { ...this.state.question };
    changedquestion.question[index] = e.target.value;
    this.setState({
      question: changedquestion,
    });
  };

  cancelchanges = (e) => {
    e.preventDefault();
    this.props.edit(e);
  };

  removeLogo = (e, type) => {
    e.stopPropagation();
    this.setState({ remove_image: true });
    if (type === "out") {
      this.setState({
        filename: null,
        formData_out: null,
        orgfilename: null,
      });
    } else {
      this.setState({
        filename: null,
        formData_in: null,
        orgfilename_in: null,
      });
    }
  };
  getUploadImage = () => {
    if (!_.isArray(this.state.question.image_data)) return true;
    else if (_.isArray(this.state.question.image_data)) {
      if (this.state.question.image_data[1] === "outside") return true;
    } else return false;
  };
  uploadFile = async (e) => {
    const MIN_FILE_SIZE = 5; // 10KB
    const MAX_FILE_SIZE = 2048; // 2MB
    const file = e.target.files[0];

    // VALIDATION BLOCK
    if (!file) {
      return notifyError("No file selected");
    }
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      return notifyError("Select valid image");
    }

    const fileSizeKiloBytes = file.size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      return notifyError("File size is less than minimum limit");
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return notifyError("File size is greater than maximum limit");
    }

    // SETTING STATE TO LATER FETCH DETAILS
    const orgfilename = e.target.files[0].name;

    if (e.target.name === "outside") {
      this.setState({ orgfilename: orgfilename, formData_out: file });
    }
    if (e.target.name === "inside") {
      this.setState({ orgfilename_in: orgfilename, formData_in: file });
    }
  };

  render() {
    let question = this.state.question.question;
    let option = this.state.question.options;
    // let question = this.state.question.question.slice(1);
    return (
      <React.Fragment>
        <div>
          {question.map((que, i) => {
            if (i === 0) {
              return (
                <div
                  id="editquestion"
                  className="quetsion--edit"
                  key={`${randomKey()}_${randomKey()}`}
                >
                  <div className="quetsion--edit--markquee">Q.</div>
                  <input
                    className={
                      this.state.isEmptyIndex === i
                        ? "quedtion--text--fields input-required"
                        : "quedtion--text--fields"
                    }
                    name={i}
                    type="text"
                    defaultValue={que.substring(7, que.length)}
                    onBlur={(e) => this.handleChange(e)}
                  />
                </div>
              );
            } else {
              return (
                <div
                  id="editquestion"
                  className="quetsion--edit"
                  key={`${randomKey()}_${randomKey()}`}
                >
                  <input
                    className={
                      this.state.isEmptyIndex === i
                        ? "quedtion--text--fields input-required"
                        : "quedtion--text--fields"
                    }
                    name={i}
                    type="text"
                    defaultValue={que}
                    onBlur={(e) => this.handleChangedValue(e)}
                  />
                </div>
              );
            }
          })}
          {this.getUploadImage() ? (
            <div className="pt-3">
              <div class="box-upload">
                <input
                  onChange={this.uploadFile}
                  accept="image/.png,.jpg,.jpeg"
                  type="file"
                  name="outside"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  id="file-5"
                  class="inputfile-4"
                  data-multiple-caption="{count} files selected"
                  multiple=""
                />
                <label for="file-5">
                  {!this.state.orgfilename ? (
                    <figure className="upload-figure">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        className="upload-svg"
                      >
                        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                      </svg>
                    </figure>
                  ) : (
                    <figure className="upload-figure">
                      <svg
                        style={{
                          width: "88%",
                          margin: "-7px 0 0 -7px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        className="upload-svg"
                      >
                        <path d="M3.75 3.75H19.5L20.25 4.5V20.25H4.5L3.75 19.5V3.75ZM5.25 5.25V12.9166L7.90909 10.2575L13.3636 15.7121L16.7727 12.303L18.75 14.2802V5.25H5.25ZM18.75 16.4016L16.7727 14.4243L13.3636 17.8334L7.90909 12.3788L5.25 15.0379V18.75H18.75V16.4016ZM14.7273 7.97727C14.0118 7.97727 13.4318 8.55727 13.4318 9.27273C13.4318 9.98819 14.0118 10.5682 14.7273 10.5682C15.4427 10.5682 16.0227 9.98819 16.0227 9.27273C16.0227 8.55727 15.4427 7.97727 14.7273 7.97727ZM11.9318 9.27273C11.9318 7.72884 13.1834 6.47727 14.7273 6.47727C16.2712 6.47727 17.5227 7.72884 17.5227 9.27273C17.5227 10.8166 16.2712 12.0682 14.7273 12.0682C13.1834 12.0682 11.9318 10.8166 11.9318 9.27273Z"></path>{" "}
                      </svg>
                    </figure>
                  )}
                  <span className="mt-3 fs-15">
                    {this.state.orgfilename && this.state.orgfilename}
                  </span>
                </label>
                {this.state.orgfilename ? (
                  <span
                    className="delete-icon"
                    onClick={(e) => this.removeLogo(e, "out")}
                  >
                    <DeleteOutlineIcon />
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: "11px",
                      color: "#797777",
                      fontWeight: "700",
                      marginLeft: "1rem",
                    }}
                  >
                    Upload an Image. <br />
                    (Size: max 2MB, Supported Fromats: jpg, jpeg, png)
                  </span>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.titleofQuestion_emsg && (
            <span className="text-danger" style={{ marginLeft: 3 + "em" }}>
              {this.state.titleofQuestion_emsg}
            </span>
          )}

          <div>
            {option.map((item, k) => {
              return (
                <div className="editable--questions" key={randomKey()}>
                  <div className="quetsion--edit--markquee">
                    <label>
                      <input
                        name={k}
                        type="checkbox"
                        value={item}
                        checked={
                          this.state.optionIndexes[k].endsWith(" *")
                            ? true
                            : false
                        }
                        onChange={(e) => this.checkBoxButtonValue(e)}
                      />
                      <span className="design"></span>
                    </label>
                  </div>

                  <input
                    className={
                      this.state.isOptionEmpty === k
                        ? "quedtion--text--fields input-required"
                        : "quedtion--text--fields"
                    }
                    name={k}
                    type="text"
                    onBlur={(e) => this.optionchange(e)}
                    defaultValue={
                      item.endsWith("*")
                        ? item.substring(0, item.length - 1)
                        : item
                    }
                  />
                </div>
              );
            })}
            {this.state.option_emsg && (
              <span className="text-danger" style={{ marginLeft: 2 + "em" }}>
                {this.state.option_emsg}
              </span>
            )}
          </div>
          <div className="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.savechanges}
            >
              Save
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.cancelchanges}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(onClickOutside(Editmediummcq));
