import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import _ from "lodash";
import produce from "immer";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent, timeEvent } from "./../utils/mixpanel";
import PhoneInput from "react-phone-input-2";
import { TextField } from "@mui/material";
import { standard } from "../utils/intialContent";
import {
  generateQuestion,
  generateQuestionFromVideo,
  wikiP1,
  wikiTopicSuggestions,
  editQuestionPaper,
  fetchUser,
  getAddOnsList,
  generateQuestionFromPdfDoc,
  QuestionPaperRating,
  getQPRating,
  addPagination,
  updateUserDetails,
  btUpdateToken,
  progress,
  generateQuestionP1,
  generateQuestionsFromPdfDocP1,
  generateQuestionFromVideoP1,
  createTest,
  purchaseAddOns,
  purchaseFreePlan,
  downloadQuestionPaper,
  validationForDownloadQuePaper,
  generateQuestionWebUrl,
  GQSearchTopic,
} from "./../services";
import MSG, {
  notifySuccess,
  notifyError,
  notifyInfo,
  profession,
  findUsList,
  validateYouTubeUrl,
  notifyWarning,
} from "./../utils/Helper";
import {
  closeReferral,
  startLoader,
  stopLoader,
  showDefaultContent,
  setInitialQues,
  setSelectedPlan,
  showRequestInQueuePrompt,
} from "./../store/reducers/generalActions";
import { authLogout } from "./../store/reducers/auth";
import EasyMCQ from "./questionTypes/easymcq";
import MediumMCQ from "./questionTypes/mediummcq";
import HardMCQ from "./questionTypes/hardmcq";
import TrueFalse from "./questionTypes/true_false";
import Descriptive from "./questionTypes/descriptive";
import FillUpsMCQ from "./questionTypes/fillups";
import Tooltip from "./common/tooltip";
import Addeasymcq from "./addquestion/addeasymcq";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Addmediummcq from "./addquestion/addmediummcq";
import Addhardmcq from "./addquestion/addhardmcq";
import Truefalse from "./addquestion/true_false";
import AddDescriptive from "./addquestion/adddescriptive";
import Fillups from "./addquestion/fillups";
import { isMobile } from "react-device-detect";
import ReferPopup from "./common/ReferPopup";
import ProgressBar from "./common/progreesBar";
import TimeInput from "./common/TimeInput";
import { ProductFruits } from "react-product-fruits";
import CustomQuestions from "./common/CustomQuestions";
import TakeAssessment from "./common/TakeAssessment";
import ToggleForGQ from "./common/ToggleForGQ";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./common/AddOnsCheckoutForm";
import QuestionPaperTopUp from "./common/QuestionPaperTopUp";
import textData from "./common/textData";
import { saveAs } from "file-saver";
import DonwloadQuestionPaper from "./common/DonwloadQuestionPaper";
import QuestionPaperSetsStandard from "./common/questionPaperSetsStandard";
import QuestionPaperPreview from "./question-paper-preview";
import GenerateQuestionStdHots from "./common/GenerateQuestionStdHots";

import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CategoryBoxStd from "./common/CategoryBoxStd";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class QuestionPaperGenerate extends Component {
  state = {
    questionPaperGenerated: false,
    titleofQuestion: this.props.defaultContent?.title,
    titleofQuestion_emsg: "",
    currentGeneratedQnTitle: this.props.defaultContent?.title,
    contentForQuestions: this.props.defaultContent?.content,
    newUser: this.props.defaultContent?.flag,
    wikiSearchInput: "",
    refreshing: false,
    typing: false,
    typingTimeout: 0,
    selectedWikiTopic: null,
    tmpselectedWikiTopic: null,
    showAlert: false,
    wikiContent: "",
    searchTopic: "",
    iseditpaper: true,
    showFeedbackForm: false,
    questions: [],
    currentActiveTab: 2,
    ques_count: {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    },
    addquestionmodal: false,
    questiontypetab: "easymcq",
    defaultActiveTab: "custom-tab",
    addeasymcq: false,
    addmediummcq: false,
    addhardmcq: false,
    addtruefalse: false,
    adddescriptive: false,
    addfillups: false,
    sortableQuestionOption: [
      {
        id: 1,
        name: "An object at rest stays at rest and an object in motion stays in motion.",
      },
      {
        id: 2,
        name: "Individuals define physics by what it was rather than what it is and will be.",
      },
      {
        id: 3,
        name: "What we mean by this is that things keep changing in the world of physics with every discovery.",
      },
      {
        id: 4,
        name: "As theories progress and discoveries are made, not only the answer but the whole question.",
      },
    ],
    isUploaded: false,
    videoFile: null,
    pdfDocFile: null,
    feedbackAlert: false,
    showRatingAlert: false,
    callRatingAPI: true,
    questionPaperId: null,
    questionPaperRating: null,
    highlightBadEmoji: null,
    highlightNeutralEmoji: null,
    highlightGoodEmoji: null,
    showPremiumPrompt: false,
    premiumPromptText: "",
    isShowPremiumPrompt: false,
    totalPages: 0,
    pageNo: 1,
    requiredQuestionCount: 11110,
    lessQuestionsGenerated: false,
    moreQuestionsGenerated: false,
    showPopup: false,
    firstEventScroll: false,
    activity_count: 0,
    isQuestionGenerated: false,
    source: "Direct",
    scrollAfterGenerate: false,
    leavePageEvent: false,
    showUpdateProfilePopUp: false,
    isShowProfilePopUp: false,
    source_from: "",
    source_from_other: "",
    profession: "",
    profession_other: "",
    data: {
      contact_number: "",
      country_code: "in",
      dial_code: "",
      formatted_phone_number: "",
    },
    showLoader: false,
    showBar: false,
    showProgressBar: false,
    progress_percent: 0,
    contentId: null,
    showEditContentPopUp: false,
    showWordLimit: false,
    searchTopicWordLimit: false,
    docPage: "",
    docPageStatement: "All pages are selected.",
    isCustomPages: false,
    isCustomDuration: false,
    startTime: null,
    endTime: null,
    timeInputMsg: null,
    ques_type: null,
    finalques_type: [],
    ques_count: null,
    totalQuestionsFormed: 0,
    showDurationAlert: false,
    timeSeconds: "00:30:00",
    timerSeconds: 30 * 60,
    stripePromise: null,
    stripeClientSecret: "",
    showStripeForm: false,
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    showAddOn: false,
    addOnText: "",
    addOnCTAText: "",
    freeAddOnSubText: "",
    addOnPlans: [],
    activatePlan: [],
    showTopUp: false,
    showDownloadPopUp: false,
    currentItem: "",
    showSetsPopUp: false,
    showPreview: false,
    categoryAccordion: false,
    with_diagram: 0,
    driveVideo: null,
    selectedWebURL: null,
    webUrlContant: null,
    isVisualsPop: false,
    isVisualText: false,
    isVisualWeb: false,
    isvisualTopic: false,
    isVisualVideo: false,
    isVisualDoc: false,
    commonTopicforVis: null,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.handleGenerateQuestion();
  };

  isFutureDate = (idate) => {
    var today = new Date().getTime(),
      idate = idate.split("/");

    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    return today - idate < 0 ? true : false;
  };

  tabChanged = (e) => {
    this.props.dispatch(setInitialQues());
    this.setState({ currentActiveTab: e });
  };

  setInitialState() {
    if (sessionStorage.getItem("show_initial_text")) {
      let topic = standard?.topic;
      let content = standard?.content;

      this.setState({
        // currentGeneratedQnTitle: this.state.titleofQuestion,
        titleofQuestion: topic,
        contentForQuestions: content,
        newUser: true,
      });
    } else {
      this.setState({
        // currentGeneratedQnTitle: this.state.titleofQuestion,
        titleofQuestion: "",
        contentForQuestions: "",
        newUser: true,
      });
    }
  }

  async componentDidMount() {
    this.setInitialState();
    this.props.dispatch(getAddOnsList());
    this.setState({ leavePageEvent: true });
    if (this.props.isAuthenticated) {
      if (!this.props.user.name) {
        this.props.dispatch(fetchUser());
      }
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      } else {
        this.props.history.replace("/generate-questions");
      }
    }

    if (this.props.location.state != undefined) {
      const { state } = this.props.location;
      if (state.editpaper) {
        const req = {
          content_id: state.editpaperid,
        };
        this.setState({
          iseditpaper: false,
          source: "Preview Question",
          contentId: state.editpaperid,
        });
        this.props.dispatch(startLoader());

        try {
          const { data: res } = await editQuestionPaper(req);
          if (res.success == true) {
            notifySuccess(res.message);
            if (!_.isEmpty(res.response)) {
              let ques_count = {
                easyCount: 0,
                mediumCount: 0,
                hardCount: 0,
                descriptiveCount: 0,
                trueFalseCount: 0,
                fillUpsCount: 0,
              };

              var easy_mcq_count =
                res.response.question_table_data.total_easy_mcq;
              ques_count.easyCount = easy_mcq_count;
              ques_count.mediumCount =
                res.response.question_table_data.total_medium_mcq;
              ques_count.hardCount =
                res.response.question_table_data.total_hard_mcq;
              ques_count.trueFalseCount =
                res.response.question_table_data.total_true_false;
              ques_count.descriptiveCount =
                res.response.question_table_data.total_descriptive;
              ques_count.fillUpsCount =
                res.response.question_table_data.total_fill_ups;

              var que_per_Page = res.response.question_table_data.perPage;
              var easy_type_page_count = Math.ceil(
                easy_mcq_count / que_per_Page
              );

              if (!res.response.rating) {
                this.setState({
                  showFeedbackForm: true,
                  showRatingAlert: true,
                });
              }

              const contentData = res.response.content_table_data;
              if (
                _.isEmpty(contentData.wiki_topic) &&
                _.isEmpty(contentData.web_url)
              ) {
                this.setState({
                  titleofQuestion: res.response.content_table_data.topic,
                  currentGeneratedQnTitle:
                    res.response.content_table_data.topic,
                  contentForQuestions:
                    res.response.content_table_data.content_text,
                  questions: [...res.response.question_table_data.questions],
                  questionPaperGenerated: true,
                  defaultActiveTab: "custom-tab",
                  ques_count: { ...ques_count },
                  pageNo: res.response.question_table_data.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: easy_type_page_count,
                });
              } else if (contentData.web_url) {
                this.setState({
                  currentActiveTab: 1,
                  titleofQuestion: res.response.content_table_data.topic,
                  currentGeneratedQnTitle:
                    res.response.content_table_data.topic,
                  selectedWebURL: res.response.content_table_data.web_url,

                  webUrlContant: res.response.content_table_data.content_text,
                  questions: [...res.response.question_table_data.questions],
                  questionPaperGenerated: true,
                  defaultActiveTab: "Weburl-tab",
                  ques_count: { ...ques_count },
                  pageNo: res.response.question_table_data.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: easy_type_page_count,
                });
              } else {
                this.setState({
                  currentActiveTab: 1,
                  titleofQuestion: res.response.content_table_data.topic,
                  currentGeneratedQnTitle:
                    res.response.content_table_data.topic,
                  selectedWikiTopic: {
                    label: res.response.content_table_data.wiki_topic,
                    value: res.response.content_table_data.wiki_topic,
                  },
                  tmpselectedWikiTopic: {
                    label: res.response.content_table_data.wiki_topic,
                    value: res.response.content_table_data.wiki_topic,
                  },
                  wikiContent: res.response.content_table_data.content_text,
                  questions: [...res.response.question_table_data.questions],
                  questionPaperGenerated: true,
                  defaultActiveTab: "wiki",
                  ques_count: { ...ques_count },
                  pageNo: res.response.question_table_data.page_no,
                  // questiontypetab: "easymcq",
                  totalPages: easy_type_page_count,
                });
              }
              if (this.props.user.update_profile_index == 0) {
                try {
                  this.scrollToQuestions();
                } catch (err) {
                  console.log(err);
                }
              }
            }
          } else {
            notifyError(res.message);
            this.resetQuestionState();
          }
        } catch (err) {
          console.log(err);
          this.resetQuestionState();
          if (err.response && err.response.status === 422) {
            const { data } = err.response;
            notifyError(data.msg);
            this.props.dispatch(authLogout());
          } else if (err.response && err.response.status === 401) {
            const { data } = err.response;
            notifyError(data.msg);
            this.props.dispatch(authLogout());
          } else {
            notifyError(MSG.commonApiError);
          }
        }
      }
      this.props.dispatch(stopLoader());
    }
    // try {
    //   const { data: res } = await addOnsList();
    //   if (res.success == true) {
    //     // notifySuccess(res.message);
    //     this.setState(
    //       {
    //         addOnsList: res.response,
    //       }
    //       // () => this.showPercentageOff()
    //     );
    //   } else {
    //     notifyError(res.message);
    //   }
    // } catch (err) {
    //   console.log(err);
    //   if (err.response && err.response.status === 422) {
    //     const { data } = err.response;
    //     notifyError(data.msg);
    //     this.props.dispatch(authLogout());
    //   } else if (err.response && err.response.status === 401) {
    //     const { data } = err.response;
    //     notifyError(data.msg);
    //     this.props.dispatch(authLogout());
    //   } else {
    //     notifyError(MSG.commonApiError);
    //   }
    // }

    // if (this.props.isAuthenticated) {
    // await this.props.dispatch(fetchUser());
    // if (this.props.user.is_free_credit == 0) {
    //   try {
    //     const req = { credits: 41 };
    //     this.props.dispatch(startLoader());
    //     const { data: res } = await updateUserDetails(req);
    //     this.props.dispatch(stopLoader());
    //     if (res.success == true) {
    //       if (res.code === 1111) {
    //         this.props.dispatch(fetchUser());
    //       }
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     this.resetQuestionState();
    //     if (err.response && err.response.status === 422) {
    //       const { data } = err.response;
    //       notifyError(data.msg);
    //       this.props.dispatch(authLogout());
    //     } else if (err.response && err.response.status === 401) {
    //       const { data } = err.response;
    //       notifyError(data.msg);
    //       this.props.dispatch(authLogout());
    //     } else {
    //       notifyError(MSG.commonApiError);
    //     }
    //   }
    // }
    // }
    if (this.props.user.country_code) {
      const country_code = _.lowerCase(this.props.user.country_code);
      this.setState((prevValue) => ({
        data: { ...prevValue.data, country_code: country_code },
      }));
    }

    // if (this.props.user.is_instruction_readed == 0) {
    //   this.setState(
    //     {
    //       showAlert: true,
    //     },
    //     async () => {
    //       await updateInstructionRead();
    //       this.props.dispatch(fetchUser());
    //     }
    //   );
    // }

    trackEvent("Generate Question Page Loaded", { source: this.state.source });
    timeEvent("Leaving Generate Question Page");
  }

  componentDidUpdate(prev) {
    if (this.props.user !== prev.user) {
      this.setInitialState();
    }
    window.onclick = () => {
      if (!this.state.firstEventScroll && this.state.activity_count == 1) {
        trackEvent("First Activity - Click");
      }
    };

    window.onscroll = (e) => {
      if (
        !this.state.firstEventScroll &&
        this.state.activity_count == 0 &&
        this.state.source == "Direct"
      ) {
        trackEvent("First Activity - Scroll");
        this.setState({
          firstEventScroll: true,
        });
      }

      if (this.state.scrollAfterGenerate) {
        trackEvent("Scroll After Question Generation");
        this.setState({
          scrollAfterGenerate: false,
        });
      }
      // Detect user scroll to the bottom of the page.
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const element = document.getElementById("scroll-to-div");
        if (element && this.state.source == "Direct") {
          trackEvent("Scroll Down To Bottom - Generate Question");
        }
      }
    };

    if (this.props.isAuthenticated) {
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      }
    }
  }

  componentWillUnmount() {
    window.onscroll = () => {};
    window.onclick = () => {};
  }

  handleCallback = (childData) => {
    let ques_count = {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    };
    ques_count.easyCount = childData.total_easy_mcq;
    ques_count.mediumCount = childData.total_medium_mcq;
    ques_count.hardCount = childData.total_hard_mcq;
    ques_count.trueFalseCount = childData.total_true_false;
    ques_count.descriptiveCount = childData.total_descriptive;
    ques_count.fillUpsCount = childData.total_fill_ups;

    var que_obj = childData.questions;
    this.setState({
      questions: [...que_obj],
      ques_count: { ...ques_count },
      pageNo: childData.page_no,
      totalPages: childData.page_no,
    });
    // console.log(this.state)

    // if (this.state.questions.length < this.state.requiredQuestionCount) {
    //   this.setState({ lessQuestionsGenerated: true });
    // }
  };

  handleCheckBox = (value) => {
    if (value) {
      this.setState({
        with_diagram: 4,
      });
    }
  };

  onInputChange = (option) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      wikiSearchInput: option,
      refreshing: true,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.getOptionsAsync(option);
      }, 2000),
    });
  };

  onInputChangeWiki = (e) => {
    this.setState({ tmpselectedWikiTopic: e.target.value });
  };

  onUrlChange = (e) => {
    this.setState({
      selectedWebURL: e.target.value,
    });
  };

  // setQuesType = (ques_type) => {
  //   console.log(ques_type)
  //   let finalques_type = ques_type.map((el) => el.value);
  //   finalques_type = finalques_type.join(",")
  //   this.setState({ ques_type: ques_type });
  //   this.setState({ finalques_type }, () => console.log(this.state.finalques_type));
  // };

  // handleQuesCount = (e) => {
  //   const regex = /^[1-9][0-9]*$/;
  //   const value = e.target.value;
  //   if (value.length < 1) {
  //     this.setState({ ques_count: "" });
  //   } else if (value.length === 1) {
  //     const reg = /^[1-9]/;
  //     if (reg.test(value)) {
  //       this.setState({ ques_count: value });
  //     } else {
  //       this.setState({ ques_count: null})
  //     }
  //   } else if (value.length > 3) {
  //     //do nothing
  //   } else {
  //     if (regex.test(value)) {
  //       this.setState({ ques_count: value });
  //     }
  //   }
  // };

  getOptionsAsync = async (val) => {
    if (val != "") {
      this.props.dispatch(startLoader());
      try {
        var wikiFormData = new FormData();
        wikiFormData.append("wiki-topic", val);
        wikiFormData.append("generateQA", "");
        const { data: res } = await wikiTopicSuggestions(wikiFormData);
        if (res.response.topic_suggestions.length == 0) {
          notifyError(
            "We couldn't find any matching Topic. Please try with some relevant Topic Name."
          );
        }
        if (res.success == true) {
          const data = res.response.topic_suggestions;
          const ldpas = data.map((s) => ({
            label: s,
            value: s,
          }));
          this.setState({
            options: ldpas,
            wikiSearchInput: val,
          });
        }
      } catch (err) {
        console.log(err);
        // notifyError(MSG.commonApiError);
      }
      this.props.dispatch(stopLoader());
    }
  };

  checkCategory = () => {
    var category_id = 1;

    if (this.state.questiontypetab === "easymcq") category_id = 1;
    else if (this.state.questiontypetab === "mediummcq") category_id = 2;
    else if (this.state.questiontypetab === "hardmcq") category_id = 3;
    else if (this.state.questiontypetab === "truefalse") category_id = 4;
    else if (this.state.questiontypetab === "descriptive") category_id = 5;
    else if (this.state.questiontypetab === "fillups") category_id = 6;

    return category_id;
  };

  handleWikiTopicSelect = async (name, option) => {
    this.setState({
      selectedWikiTopic: option,
      tmpselectedWikiTopic: option,
      [name]: option.value,
    });
    this.props.dispatch(startLoader());
    try {
      var formData = new FormData();
      formData.append("wiki-topic", option.value);
      formData.append("generateQA", "");
      const { data: res } = await wikiTopicSuggestions(formData);
      // console.log(res);
      if (res.success == true) {
        const wikiContent = res.response.content;
        this.setState({
          wikiSearchInput: option.value,
          wikiContent: wikiContent,
        });
      }
    } catch (err) {
      console.log(err);
      // notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  handleWikiTopicFocus = () => {
    this.setState({
      tmpselectedWikiTopic: null,
      options: [],
      activity_count: this.state.activity_count + 1,
    });
  };

  handleWikiTopicBlur = () => {
    if (this.state.tmpselectedWikiTopic == null) {
      this.setState({
        tmpselectedWikiTopic: this.state.selectedWikiTopic,
        options: [],
      });
    }
  };

  handleGenerateQuestionForWiki = async (isVisuals) => {
    if (isVisuals == "isViuals") {
      this.setState({ isVisualsPop: false, isvisualTopic: false });
    }
    trackEvent("Proceeded to Generate Questions From Search Topics", {
      clicks: this.state.activity_count,
    });
    timeEvent("Questions Generated Successfully - Search Topics");
    if (this.countWords(this.state.wikiContent) < 50) {
      this.setState({ searchTopicWordLimit: true });
      return;
    }

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }
    if (this.props.user.update_profile_index) {
      if (this.state.showRatingAlert) {
        this.setState({
          isShowProfilePopUp: true,
        });
      } else {
        this.setState({
          showUpdateProfilePopUp: true,
        });
      }
    }
    /*if (this.props.user.remaining_runs == 0) {
      notifyError(
        "You don't have runs to generate question. Please purchase plan"
      );
      this.props.history.push("/plans");
      return;
    } */

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    // this.props.dispatch(startLoader());
    //if (this.state.callRatingAPI == true) {
    // try {
    //   const { data: res } = await getQPRating();
    //   if (res.success == true && res.rating == null) {
    //     this.setState({
    //       feedbackAlert: true,
    //       questionPaperId: res.response.content_id,
    //       questionPaperRating: res.response.rating,
    //       callRatingAPI: false,
    //     });
    //     if (this.props.user.update_profile_index == 0) {
    //       if (
    //         this.state.feedbackAlert == true &&
    //         this.state.questionPaperRating == null
    //       ) {
    //         this.setState({
    //           showRatingAlert: true,
    //           // showFeedbackForm: true
    //         });
    //       }
    //     }
    //   }
    // } catch (err) {
    //   console.log(err);
    //   if (err.response && err.response.status === 422) {
    //     const { data } = err.response;
    //     notifyError(data.msg);
    //     this.props.dispatch(authLogout());
    //   } else if (err.response && err.response.status === 401) {
    //     const { data } = err.response;
    //     notifyError(data.msg);
    //     this.props.dispatch(authLogout());
    //   } else {
    //     notifyError(MSG.commonApiError);
    //   }
    // }
    //console.log("wiki-content",this.state.wikiContent)
    // console.log("wiki-topic", this.state.selectedWikiTopic.value);
    //console.log("this.state.generateQA",this.state.generateQA)

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    const { quesCount, quesTypes, with_diagram } = this.props.customQuesType;
    let visuals = isVisuals === "isViuals" ? 0 : with_diagram;
    var myformData = new FormData();
    let topic =
      this.state.tmpselectedWikiTopic &&
      typeof this.state.tmpselectedWikiTopic === "object" &&
      "value" in this.state.tmpselectedWikiTopic
        ? this.state.tmpselectedWikiTopic.value
        : this.state.tmpselectedWikiTopic;

    myformData.append("topic", this.state.titleofQuestion);
    myformData.append("wiki-topic", topic);
    myformData.append("wiki-content", this.state.wikiContent);
    myformData.append("device", device);
    myformData.append("quesType", quesTypes);
    myformData.append("with_diagram", visuals);
    let backupContant = this.state.wikiContent;
    let backupTitle = this.state.titleofQuestion;
    if (quesCount) myformData.append("quesCount", quesCount);
    myformData.append("generateQA", "GenerateQuestions");

    try {
      const { data: res } = await wikiP1(myformData);
      console.log("reddd", res);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          var formData = new FormData();
          formData.append("wiki-content", this.state.wikiContent);
          formData.append("content_id", contentId);
          formData.append("category_id", category_id);
          formData.append("generateQA", "GenerateQuestions");
          try {
            const { data: res } = await wikiTopicSuggestions(formData);
            if (res.success == true) {
              if (res.code === 103) {
                this.setState({
                  isVisualsPop: true,
                  isvisualTopic: true,
                  titleofQuestion: backupTitle,
                  contentForQuestions: backupContant,
                });
              } else {
                notifySuccess(res.message);
                this.props.dispatch(fetchUser());

                // if (res.code == 1111) {
                //   /* Check if new notification addded to db and update it */
                //   this.props.dispatch(fetchUser());
                // }
                var que_obj = res.response.questions;
                if (!_.isEmpty(res.response)) {
                  trackEvent(
                    "Questions Generated Successfully - Search Topics",
                    {
                      PlanName: this.props.user.plan_details.plan_name,
                    }
                  );
                  timeEvent("Trying Next Content");

                  const total_ques_formed =
                    res.response.total_generated_questions;
                  const total_ques_expected = res.response.expected_records;

                  if (total_ques_expected) {
                    if (total_ques_expected !== "N/A") {
                      if (total_ques_formed < total_ques_expected) {
                        this.setState({ lessQuestionsGenerated: true });
                      } else if (total_ques_formed > total_ques_expected) {
                        this.setState({ moreQuestionsGenerated: true });
                      }
                    }
                  }

                  let ques_count = {
                    easyCount: 0,
                    mediumCount: 0,
                    hardCount: 0,
                    descriptiveCount: 0,
                    trueFalseCount: 0,
                    fillUpsCount: 0,
                  };

                  var total_records = res.response.total_easy_mcq;
                  if (category_id == 2)
                    var total_records = res.response.total_medium_mcq;
                  if (category_id == 3)
                    var total_records = res.response.total_hard_mcq;
                  if (category_id == 4)
                    var total_records = res.response.total_true_false;
                  if (category_id == 5)
                    var total_records = res.response.total_descriptive;
                  if (category_id == 6)
                    var total_records = res.response.total_fill_ups;

                  ques_count.easyCount = res.response.total_easy_mcq;
                  ques_count.mediumCount = res.response.total_medium_mcq;
                  ques_count.hardCount = res.response.total_hard_mcq;
                  ques_count.trueFalseCount = res.response.total_true_false;
                  ques_count.descriptiveCount = res.response.total_descriptive;
                  ques_count.fillUpsCount = res.response.total_fill_ups;

                  var que_per_Page = res.response.perPage;
                  var totalPages = Math.ceil(total_records / que_per_Page);

                  this.setState({
                    questions: [...que_obj],
                    questionPaperGenerated: true,
                    ques_count: { ...ques_count },
                    pageNo: res.response.page_no,
                    // questiontypetab: "easymcq",
                    totalPages: totalPages,
                    isQuestionGenerated: true,
                    totalQuestionsFormed: total_ques_formed,
                  });
                  if (this.props.user.update_profile_index == 0) {
                    try {
                      this.scrollToQuestions();
                    } catch (err) {
                      console.log(err);
                    }
                  }
                  await this.sleep(1000);
                  this.setState({
                    scrollAfterGenerate: true,
                    showRatingAlert: true,
                  });
                }
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - Search Topics");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Search Topics");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else if (res.code === 429) {
        this.props.dispatch(showRequestInQueuePrompt());
        // notifyWarning(res.message)
      } else {
        notifyError(res.message);

        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            // Code 1199 Signals to Purchase Free Plan.
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
            });
          }

          if (this.props.user.plan_details.active_subscription) {
            if (res.code == 1031) {
              // Code 1031 means Question Paper Limits Exhausted and Show Top UP Question Papers Prompt.
              this.setState({
                showRatingAlert: false,
                showTopUp: true,
              });
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  handleGenerateUrl = async (isVisuals) => {
    if (isVisuals == "isViuals") {
      this.setState({ isVisualsPop: false, isVisualWeb: false });
    }
    if (this.state.webUrlContant.length < 50) {
      notifyError("Provide contant more than 50");
      return;
    }
    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }
    if (this.props.user.update_profile_index) {
      if (this.state.showRatingAlert) {
        this.setState({
          isShowProfilePopUp: true,
        });
      } else {
        this.setState({
          showUpdateProfilePopUp: true,
        });
      }
    }
    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }
    this.setState({
      showProgressBar: true,
    });

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var myformData = new FormData();
    const { quesCount, quesTypes, with_diagram } = this.props.customQuesType;
    let visuals = isVisuals === "isViuals" ? 0 : with_diagram;
    myformData.append("topic", this.state.titleofQuestion);
    myformData.append("content", this.state.webUrlContant);
    myformData.append("web_url", this.state.selectedWebURL || " ");
    myformData.append("device", device);
    myformData.append("quesType", quesTypes);
    myformData.append("with_diagram", visuals);
    myformData.append("generateContant", "generated");
    let backupContant = this.state.webUrlContant;
    let backupTitle = this.state.titleofQuestion;
    if (quesCount) myformData.append("quesCount", quesCount);
    try {
      const { data: res } = await generateQuestionWebUrl(myformData);
      console.log("helllo", res);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          try {
            // const { data: res } = await generateQuestion(req);
            const { data: res } = await generateQuestion({
              content_id: contentId,
              category_id: category_id,
            });

            if (res.success == true) {
              if (res.code === 103) {
                // this.setState({});
                this.setState({
                  isVisualsPop: true,
                  isVisualWeb: true,
                  titleofQuestion: backupTitle,
                  contentForQuestions: backupContant,
                });
              } else {
                this.props.dispatch(fetchUser());

                notifySuccess(res.message);
                var que_obj = res.response.questions;

                if (!_.isEmpty(res.response)) {
                  const total_ques_formed =
                    res.response.total_generated_questions;
                  const total_ques_expected = res.response.expected_records;

                  if (total_ques_expected) {
                    if (total_ques_expected !== "N/A") {
                      if (total_ques_formed < total_ques_expected) {
                        this.setState({ lessQuestionsGenerated: true });
                      } else if (total_ques_formed > total_ques_expected) {
                        this.setState({ moreQuestionsGenerated: true });
                      }
                    }
                  }

                  let ques_count = {
                    easyCount: 0,
                    mediumCount: 0,
                    hardCount: 0,
                    descriptiveCount: 0,
                    trueFalseCount: 0,
                    fillUpsCount: 0,
                  };

                  var total_records = res.response.total_easy_mcq;
                  if (category_id == 2)
                    var total_records = res.response.total_medium_mcq;
                  if (category_id == 3)
                    var total_records = res.response.total_hard_mcq;
                  if (category_id == 4)
                    var total_records = res.response.total_true_false;
                  if (category_id == 5)
                    var total_records = res.response.total_descriptive;
                  if (category_id == 6)
                    var total_records = res.response.total_fill_ups;

                  ques_count.easyCount = res.response.total_easy_mcq;
                  ques_count.mediumCount = res.response.total_medium_mcq;
                  ques_count.hardCount = res.response.total_hard_mcq;
                  ques_count.trueFalseCount = res.response.total_true_false;
                  ques_count.descriptiveCount = res.response.total_descriptive;
                  ques_count.fillUpsCount = res.response.total_fill_ups;

                  var que_per_Page = res.response.perPage;
                  var totalPages = Math.ceil(total_records / que_per_Page);

                  this.setState({
                    questions: [...que_obj],
                    questionPaperGenerated: true,
                    ques_count: { ...ques_count },
                    pageNo: res.response.page_no,
                    // questiontypetab: "easymcq",
                    totalPages: totalPages,
                    isQuestionGenerated: true,
                    totalQuestionsFormed: total_ques_formed,
                    newUser: false,
                    //callRatingAPI: true,
                  });
                  if (this.props.user.update_profile_index == 0) {
                    try {
                      this.scrollToQuestions();
                    } catch (err) {
                      console.log(err);
                    }
                  }

                  await this.sleep(1000);
                  this.setState({
                    showRatingAlert: true,
                    scrollAfterGenerate: true,
                  });
                }
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - Type/Paste Text");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Type/Paste Text");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else {
        if (res.code === 429) {
          this.props.dispatch(showRequestInQueuePrompt());
          // notifyWarning(res.message)
        } else {
          notifyError(res.message);

          if (!this.props.user.plan_details.is_new_pricing) {
            if (
              res.code === 111 &&
              this.props.user.plan_details.plan_name === "Free"
            ) {
              this.setState({
                premiumPromptText: res.message,
                isShowPremiumPrompt: true,
              });
              if (this.state.showRatingAlert == false) {
                this.setState({
                  showPremiumPrompt: true,
                });
              }
            }
          } else {
            if (res.code == 1199) {
              this.setState({
                showRatingAlert: false,
                showAddOn: true,
                activatePlan: this.props.addOnsList.Free,
                addOnText: textData.FreeAddOnText,
                addOnCTAText: textData.AddOnCTAText4,
                freeAddOnSubText: textData.FreeAddOnSubText.replace(
                  "{price}",
                  this.props.addOnsList.Free.currency_symbol +
                    this.props.addOnsList.Free.price
                ),
              });
            }

            if (this.props.user.plan_details.active_subscription) {
              if (res.code == 1031) {
                this.setState({
                  showRatingAlert: false,
                  showTopUp: true,
                });
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }

    clearInterval(this.intervalId);

    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  resetQuestionState = () => {
    this.setState({
      questions: [],
      questionPaperGenerated: false,
      ques_count: {
        easyCount: 0,
        mediumCount: 0,
        hardCount: 0,
        descriptiveCount: 0,
        trueFalseCount: 0,
        fillUpsCount: 0,
      },
    });
  };

  scrollToQuestions = () => {
    const position = document.getElementById("scroll-to-div").offsetTop;
    window.scrollTo(0, position);
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleAddQuestionOnClick = () => {
    this.addquestion();
    this.scrollToBottom();
  };

  getProgressPercentage = async (contentId) => {
    this.intervalId = setInterval(async () => {
      if (this.state.progress_percent < 100) {
        try {
          const { data: res } = await progress({ content_id: contentId });
          if (res.success == true) {
            this.setState({
              progress_percent: res.response.progress,
            });
          }
        } catch (err) {
          console.log("err", err);
          // notifyError(MSG.commonApiError);
        }
      }
    }, 1000);
  };

  countWords = (str) => {
    const arr = str.split(" ");
    return arr.filter((word) => word !== "").length;
  };

  handleGenerateQuestion = async (isVisuals) => {
    if (isVisuals == "isViuals") {
      this.setState({ isVisualsPop: false, isVisualText: false });
    }

    if (this.countWords(this.state.contentForQuestions) < 50) {
      this.setState({ showWordLimit: true });
      return;
    }

    if (sessionStorage.getItem("show_initial_text")) {
      this.setState({ showEditContentPopUp: true });
      return;
    }

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }

    if (!this.validateTopicInput()) {
      return false;
    }

    if (this.props.user.update_profile_index) {
      if (this.state.showRatingAlert) {
        this.setState({
          isShowProfilePopUp: true,
        });
      } else {
        this.setState({
          showUpdateProfilePopUp: true,
        });
      }
    }
    /*if (this.props.user.remaining_runs == 0) {
      notifyError(
        "You don't have runs to generate question. Please purchase plan"
      );
      this.props.history.push("/plans");
      return;
    } */

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var myformData = new FormData();
    const { quesCount, quesTypes, with_diagram } = this.props.customQuesType;
    let visuals = isVisuals === "isViuals" ? 0 : with_diagram;

    myformData.append("topic", this.state.titleofQuestion);
    myformData.append("content", this.state.contentForQuestions);
    myformData.append("device", device);
    myformData.append("quesType", quesTypes);
    myformData.append("with_diagram", visuals);
    if (quesCount) myformData.append("quesCount", quesCount);
    let backupContant = this.state.contentForQuestions;
    let backupTitle = this.state.titleofQuestion;
    console.log(visuals);
    try {
      const { data: res } = await generateQuestionP1(myformData);
      if (res.success === true) {
        await this.props.dispatch(fetchUser());
        // await sessionStorage.removeItem("show_initial_text");

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          try {
            console.log("In generate Question", backupContant);
            // const { data: res } = await generateQuestion(req);
            const { data: res } = await generateQuestion({
              content_id: contentId,
              category_id: category_id,
            });

            if (res.success == true) {
              if (res.code === 103) {
                // this.setState({});
                this.setState({
                  isVisualsPop: true,
                  isVisualText: true,
                  titleofQuestion: backupTitle,
                  contentForQuestions: backupContant,
                });
              } else {
                this.props.dispatch(fetchUser());

                notifySuccess(res.message);
                var que_obj = res.response.questions;

                if (!_.isEmpty(res.response)) {
                  const total_ques_formed =
                    res.response.total_generated_questions;
                  const total_ques_expected = res.response.expected_records;

                  if (total_ques_expected) {
                    if (total_ques_expected !== "N/A") {
                      if (total_ques_formed < total_ques_expected) {
                        this.setState({ lessQuestionsGenerated: true });
                      } else if (total_ques_formed > total_ques_expected) {
                        this.setState({ moreQuestionsGenerated: true });
                      }
                    }
                  }

                  let ques_count = {
                    easyCount: 0,
                    mediumCount: 0,
                    hardCount: 0,
                    descriptiveCount: 0,
                    trueFalseCount: 0,
                    fillUpsCount: 0,
                  };

                  var total_records = res.response.total_easy_mcq;
                  if (category_id == 2)
                    var total_records = res.response.total_medium_mcq;
                  if (category_id == 3)
                    var total_records = res.response.total_hard_mcq;
                  if (category_id == 4)
                    var total_records = res.response.total_true_false;
                  if (category_id == 5)
                    var total_records = res.response.total_descriptive;
                  if (category_id == 6)
                    var total_records = res.response.total_fill_ups;

                  ques_count.easyCount = res.response.total_easy_mcq;
                  ques_count.mediumCount = res.response.total_medium_mcq;
                  ques_count.hardCount = res.response.total_hard_mcq;
                  ques_count.trueFalseCount = res.response.total_true_false;
                  ques_count.descriptiveCount = res.response.total_descriptive;
                  ques_count.fillUpsCount = res.response.total_fill_ups;

                  var que_per_Page = res.response.perPage;
                  var totalPages = Math.ceil(total_records / que_per_Page);

                  this.setState({
                    questions: [...que_obj],
                    questionPaperGenerated: true,
                    ques_count: { ...ques_count },
                    pageNo: res.response.page_no,
                    // questiontypetab: "easymcq",
                    totalPages: totalPages,
                    isQuestionGenerated: true,
                    totalQuestionsFormed: total_ques_formed,
                    newUser: false,
                    //callRatingAPI: true,
                  });
                  if (this.props.user.update_profile_index == 0) {
                    try {
                      this.scrollToQuestions();
                    } catch (err) {
                      console.log(err);
                    }
                  }

                  await this.sleep(1000);
                  this.setState({
                    showRatingAlert: true,
                    scrollAfterGenerate: true,
                  });
                }
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - Type/Paste Text");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Type/Paste Text");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else {
        if (res.code === 429) {
          this.props.dispatch(showRequestInQueuePrompt());
          // notifyWarning(res.message)
        } else {
          notifyError(res.message);

          if (!this.props.user.plan_details.is_new_pricing) {
            if (
              res.code === 111 &&
              this.props.user.plan_details.plan_name === "Free"
            ) {
              this.setState({
                premiumPromptText: res.message,
                isShowPremiumPrompt: true,
              });
              if (this.state.showRatingAlert == false) {
                this.setState({
                  showPremiumPrompt: true,
                });
              }
            }
          } else {
            if (res.code == 1199) {
              this.setState({
                showRatingAlert: false,
                showAddOn: true,
                activatePlan: this.props.addOnsList.Free,
                addOnText: textData.FreeAddOnText,
                addOnCTAText: textData.AddOnCTAText4,
                freeAddOnSubText: textData.FreeAddOnSubText.replace(
                  "{price}",
                  this.props.addOnsList.Free.currency_symbol +
                    this.props.addOnsList.Free.price
                ),
              });
            }

            if (this.props.user.plan_details.active_subscription) {
              if (res.code == 1031) {
                this.setState({
                  showRatingAlert: false,
                  showTopUp: true,
                });
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }

    clearInterval(this.intervalId);

    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  handleRatingComment = (e) => {
    this.setState({
      ratingComment: e.target.value,
    });
  };

  //GET Timestamp for the video files
  startTimeStampHandler = (secs) => {
    this.setState({ startTime: secs });
  };

  endTimeStampHandler = (secs) => {
    this.setState({ endTime: secs });
  };

  isCustomDurationHandler = () => {
    this.setState({ isCustomDuration: true });
  };

  startTypingTime = () => {
    if (this.state.timeInputMsg) {
      this.setState({ timeInputMsg: null });
    }
  };

  handleClearCustomDuration = () => {
    this.setState({
      isCustomDuration: false,
      timeInputMsg: null,
      startTime: null,
      endTime: null,
    });
  };

  validateTimeInput = () => {
    const { startTime, endTime } = this.state;
    if (!startTime || !endTime) {
      return true;
    } else if (startTime === endTime) {
      this.setState({ timeInputMsg: "Start & End time can't be same" });
      return false;
    } else if (startTime > endTime) {
      this.setState({ timeInputMsg: "End time can't be less than Start time" });
      return false;
    }
    return true;
  };

  getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        var fileObj = chosenFiles.map((f) => {
          return f.fileObject;
        });
        this.setState({ videoFile: fileObj[0], isUploaded: true });
      });
    });
  };

  handleFileStatusChange = ({ meta, file }, status) => {
    if (status === "done") {
      this.setState({ lengthOfVideo: meta.duration });
    }
    if (status == "removed") {
      this.setState({ videoFile: null, isUploaded: false });
    }
  };

  getDocPdfFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        var fileObj = chosenFiles.map((f) => f.fileObject);
        this.setState({ pdfDocFile: fileObj[0] });
      });
    });
  };

  handlePdfDocFileStatusChange = ({ meta, file }, status) => {
    if (status == "removed") {
      this.setState({ pdfDocFile: null });
    }
  };

  validateTopicInput = () => {
    if (_.trim(this.state.titleofQuestion) == "") {
      this.setState(
        {
          titleofQuestion_emsg: "Please enter Chapter name, Any topic, etc.",
        },
        () => {
          const position = document.getElementById("titleofQuestion").offsetTop;
          window.scrollTo(0, position);
          document.getElementById("titleofQuestion").focus();
        }
      );
      return false;
    }
    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  videoExtractButtonContent = () => {
    if (this.props.user.plan_details) {
      return (
        <React.Fragment>
          <React.Fragment>
            {!this.props.user.plan_details.video_is_allowed ? (
              <Link type="button" className="btn--submit" to="/plans">
                Upgrade Plan
              </Link>
            ) : (
              <button
                type="button"
                className="btn--submit"
                onClick={this.handleGenerateQuestionFromVideo}
                disabled={
                  this.state.videourl || this.state.isUploaded
                    ? false
                    : this.props?.user?.plan_details?.is_new_pricing ||
                      this.state.driveVideo
                    ? true
                    : false
                }
              >
                Extract Content
              </button>
            )}
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  handleGenerateQuestionFromVideo = async (isVisuals) => {
    if (this.state.isUploaded !== true) {
      if (!validateYouTubeUrl(this.state.videourl)) {
        notifyError("Please enter a valid YouTube URL");
        return;
      }
    }
    trackEvent("Proceeded to Generate Questions From Video/URL", {
      clicks: this.state.activity_count,
    });
    timeEvent("Questions Generated Successfully - Video/URL");

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }
    if (isVisuals == "isViuals") {
      this.setState({ isVisualsPop: false, isVisualVideo: false });
    }

    if (!this.validateTopicInput()) {
      return false;
    }
    if (!this.validateTimeInput()) {
      return false;
    }

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var formData = new FormData();
    const { quesCount, quesTypes, with_diagram } = this.props.customQuesType;
    let visuals = isVisuals === "isViuals" ? 0 : with_diagram;
    formData.append("topic", this.state.titleofQuestion);
    formData.append("device", device);
    formData.append("start_point", this.state.startTime);
    formData.append("end_point", this.state.endTime);
    formData.append("quesType", quesTypes);
    formData.append("with_diagram", visuals);
    let backupTitle = this.state.titleofQuestion;
    if (quesCount) formData.append("quesCount", quesCount);

    let filetype = "";
    if (this.state.isUploaded == true && !this.state.driveVideo) {
      formData.append("file", this.state.videoFile);
      formData.append("type", "video");
      filetype = "video";
    } else if (this.state.driveVideo) {
      formData.append("gdrive", this.state.driveVideo);
      formData.append("type", "gdrive");
      filetype = "gdrive";
    } else {
      formData.append("url", this.state.videourl);
      formData.append("type", "url");
      filetype = "url";
    }
    try {
      // const { data: res } = await generateQuestion(req);
      const { data: res } = await generateQuestionFromVideoP1(formData);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();

          try {
            const { data: res } = await generateQuestionFromVideo({
              type: filetype,
              content_id: contentId,
              category_id: category_id,
            });
            if (res.success == true) {
              if (res.code === 103) {
                this.setState({
                  isVisualsPop: true,
                  isVisualVideo: true,
                  titleofQuestion: backupTitle,
                });
              } else {
                notifySuccess(res.message);
                this.props.dispatch(fetchUser());

                // if (res.code == 1111) {
                //   /* Check if new notification addded to db and update it */
                //   this.props.dispatch(fetchUser());
                // }
                var que_obj = res.response.questions;
                if (!_.isEmpty(res.response)) {
                  const total_ques_formed =
                    res.response.total_generated_questions;
                  const total_ques_expected = res.response.expected_records;

                  if (total_ques_expected) {
                    if (total_ques_expected !== "N/A") {
                      // console.log(total_ques_expected , total_ques_formed)
                      if (total_ques_formed < total_ques_expected) {
                        this.setState({ lessQuestionsGenerated: true });
                      }
                    }
                  }

                  let ques_count = {
                    easyCount: 0,
                    mediumCount: 0,
                    hardCount: 0,
                    descriptiveCount: 0,
                    trueFalseCount: 0,
                    fillUpsCount: 0,
                  };

                  var total_records = res.response.total_easy_mcq;
                  if (category_id == 2)
                    var total_records = res.response.total_medium_mcq;
                  if (category_id == 3)
                    var total_records = res.response.total_hard_mcq;
                  if (category_id == 4)
                    var total_records = res.response.total_true_false;
                  if (category_id == 5)
                    var total_records = res.response.total_descriptive;
                  if (category_id == 6)
                    var total_records = res.response.total_fill_ups;

                  ques_count.easyCount = res.response.total_easy_mcq;
                  ques_count.mediumCount = res.response.total_medium_mcq;
                  ques_count.hardCount = res.response.total_hard_mcq;
                  ques_count.trueFalseCount = res.response.total_true_false;
                  ques_count.descriptiveCount = res.response.total_descriptive;
                  ques_count.fillUpsCount = res.response.total_fill_ups;

                  var que_per_Page = res.response.perPage;
                  var totalPages = Math.ceil(total_records / que_per_Page);

                  this.setState({
                    questions: [...que_obj],
                    questionPaperGenerated: true,
                    ques_count: { ...ques_count },
                    pageNo: res.response.page_no,
                    // questiontypetab: "easymcq",
                    totalPages: totalPages,
                    isQuestionGenerated: true,
                    totalQuestionsFormed: total_ques_formed,
                  });
                  if (this.props.user.update_profile_index == 0) {
                    try {
                      this.scrollToQuestions();
                    } catch (err) {
                      console.log(err);
                    }
                  }
                  await this.sleep(1000);
                  this.setState({
                    showRatingAlert: true,
                    scrollAfterGenerate: true,
                  });
                }
              }
            } else {
              this.resetQuestionState();
              notifyError(res.message);
              if (!this.props.user.plan_details.is_new_pricing) {
                if (
                  res.code === 111 &&
                  this.props.user.plan_details.plan_name === "Free"
                ) {
                  this.setState({
                    premiumPromptText: res.message,
                    isShowPremiumPrompt: true,
                  });
                  if (this.state.showRatingAlert == false) {
                    this.setState({
                      showPremiumPrompt: true,
                    });
                  }
                }
              } else {
                if (res.code == 1199) {
                  this.setState({
                    showRatingAlert: false,
                    showAddOn: true,
                    activatePlan: this.props.addOnsList.Free,
                    addOnText: textData.FreeAddOnText,
                    addOnCTAText: textData.AddOnCTAText4,
                    freeAddOnSubText: textData.FreeAddOnSubText.replace(
                      "{price}",
                      this.props.addOnsList.Free.currency_symbol +
                        this.props.addOnsList.Free.price
                    ),
                    showProgressBar: false,
                    contentId: null,
                    progress_percent: 0,
                  });
                }

                if (this.props.user.plan_details.active_subscription) {
                  if (res.code == 1031) {
                    this.setState({
                      showRatingAlert: false,
                      showTopUp: true,
                    });
                  }

                  let active_plan = this.props.user.plan_details.plan_name;
                  if (res.code == 1099) {
                    this.setState({
                      showRatingAlert: false,
                      showAddOn: true,
                      activatePlan: this.props.addOnsList.Video[active_plan],
                      addOnText: textData.VideoAddOnText,
                      addOnCTAText: textData.AddOnCTAText2.replace(
                        "{price}",
                        this.props.addOnsList.Video[active_plan]
                          .currency_symbol +
                          this.props.addOnsList.Video[active_plan].price
                      ),
                    });
                  }
                }
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - Video/URL");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else if (res.code === 429) {
        this.props.dispatch(showRequestInQueuePrompt());
        // notifyWarning(res.message)
      } else {
        notifyError(res.message);
        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
              showProgressBar: false,
              contentId: null,
              progress_percent: 0,
            });
          }

          if (this.props.user.plan_details.active_subscription) {
            if (res.code == 1031) {
              this.setState({
                showRatingAlert: false,
                showTopUp: true,
              });
            }

            let active_plan = this.props.user.plan_details.plan_name;
            if (res.code == 1099) {
              this.setState({
                showRatingAlert: false,
                showAddOn: true,
                activatePlan: this.props.addOnsList.Video[active_plan],
                addOnText: textData.VideoAddOnText,
                addOnCTAText: textData.AddOnCTAText2.replace(
                  "{price}",
                  this.props.addOnsList.Video[active_plan].currency_symbol +
                    this.props.addOnsList.Video[active_plan].price
                ),
              });
            }
          }
        }
      }
    } catch (err) {
      trackEvent("Questions Generation Failed - Video/URL");
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        // notifyError(MSG.commonApiError);
        console.log("Error");
      }
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  // To show custom page input field
  customPageHandler = () => {
    this.setState({ isCustomPages: true });
  };

  // handle
  handleClearCustomPages = () => {
    this.setState({ docPage: "", isCustomPages: false });
  };

  //  Set value for page input and validation only accepts (1,2,6-7,8 kind of values)
  handleDocPage = (event) => {
    // To Check if user have pressed backspace and delete the value
    const pressedBackSpace =
      event.nativeEvent.inputType === "deleteContentBackward";
    if (pressedBackSpace) {
      this.setState((prevValue) => {
        let previousPages = prevValue.docPage;
        if (previousPages) {
          previousPages = previousPages.substr(0, previousPages.length - 1);
        }
        return { docPage: previousPages };
      });
      return;
    }

    // regex to check if entered values are only numbers commas and hyphens
    const regex = /^[\d,-]+$/;
    let value = event.target.value;

    if (value.startsWith("-") || value.startsWith(",")) {
      return;
    }
    if (value.trim() !== "") {
      if (!pressedBackSpace && value.length > 200) {
        return;
      }
      if (value.endsWith("--")) {
        const lastIndex = value.lastIndexOf("-");
        const replacement = "";
        const replaced =
          value.slice(0, lastIndex) + replacement + value.slice(lastIndex + 1);
        this.setState({ docPage: replaced });
      } else if (value.endsWith(",,")) {
        const lastIndex = value.lastIndexOf(",");
        const replacement = "";
        const replaced =
          value.slice(0, lastIndex) + replacement + value.slice(lastIndex + 1);
        this.setState({ docPage: replaced });
      } else if (value.endsWith(" ")) {
        this.setState((prevValue) => {
          let lastvalue = prevValue.docPage;
          if (lastvalue.endsWith(",") || lastvalue.endsWith("-")) {
            return;
          }
          const replaced = lastvalue + ",";
          return { docPage: replaced };
        });
      } else if (value.endsWith("-") || value.endsWith(",")) {
        this.setState((prevValue) => {
          let lastvalue = prevValue.docPage;
          if (lastvalue.endsWith(",") || lastvalue.endsWith("-")) {
            return;
          } else if (lastvalue.length > 1 && value.endsWith("-")) {
            const pattern = /\d+$/;
            let matches = lastvalue.match(pattern);
            if (
              lastvalue.charAt(lastvalue.length - (matches[0].length + 1)) ===
              "-"
            ) {
              return;
            }
          }
          return { docPage: value };
        });
      } else {
        if (regex.test(value)) {
          this.setState({ docPage: value });
        }
      }
    } else {
      this.setState({
        docPage: "",
        docPageStatement: "All pages are selected.",
      });
    }
  };
  // To remove - or , if any in the end
  getFinalPageList = () => {
    const docPage = this.state.docPage;
    if (!_.isEmpty(docPage)) {
      if (docPage.endsWith("-") || docPage.endsWith(",")) {
        let newDocPage = docPage.substr(0, docPage.length - 1);
        this.setState({ docPage: newDocPage });
      }
    }
  };

  handleGenerateQuestionFromPdfDoc = async (isVisuals) => {
    trackEvent("Proceeded to Generate Questions From PDF/Doc", {
      clicks: this.state.activity_count,
    });
    timeEvent("Questions Generated Successfully - PDF/Doc");

    this.getFinalPageList();

    if (this.state.isQuestionGenerated) {
      trackEvent("Trying Next Content");
    }
    if (isVisuals == "isViuals") {
      this.setState({
        isVisualsPop: false,
        isVisualDoc: false,
      });
    }

    if (!this.validateTopicInput()) {
      return false;
    }

    if (this.props.user.update_profile_index) {
      if (this.state.showRatingAlert) {
        this.setState({
          isShowProfilePopUp: true,
        });
      } else {
        this.setState({
          showUpdateProfilePopUp: true,
        });
      }
    }

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.setState({
      showProgressBar: true,
    });

    var device = "Desktop";

    if (isMobile == true) {
      device = "Mobile";
    }

    var formData = new FormData();

    const { quesCount, quesTypes, with_diagram } = this.props.customQuesType;
    let visuals = isVisuals === "isViuals" ? 0 : with_diagram;
    formData.append("topic", this.state.currentGeneratedQnTitle);
    formData.append("file", this.state.pdfDocFile);
    formData.append("device", device);
    formData.append("pageCounts", this.state.docPage);
    formData.append("quesType", quesTypes);
    formData.append("with_diagram", visuals);
    let backUpTitle = this.state.currentGeneratedQnTitle;
    if (quesCount) formData.append("quesCount", quesCount);

    if (this.props.user.update_profile_index) {
      if (this.state.showRatingAlert) {
        this.setState({
          isShowProfilePopUp: true,
        });
      } else {
        this.setState({
          showUpdateProfilePopUp: true,
        });
      }
    }

    try {
      const { data: res } = await generateQuestionsFromPdfDocP1(formData);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());

        if (res.code === 1008) {
          notifySuccess(res.message);
        } else {
          var contentId = res.response.contentId;
          this.getProgressPercentage(contentId);
          this.setState({
            contentId: contentId,
          });

          var category_id = this.checkCategory();
          try {
            const { data: res } = await generateQuestionFromPdfDoc({
              content_id: contentId,
              category_id: category_id,
            });
            if (res.success == true) {
              if (res.code === 103) {
                this.setState({
                  isVisualsPop: true,
                  isVisualDoc: true,
                  titleofQuestion: backUpTitle,
                });
              } else {
                notifySuccess(res.message);
                this.props.dispatch(fetchUser());

                // if (res.code == 1111) {
                //   /* Check if new notification addded to db and update it */
                //   this.props.dispatch(fetchUser());
                // }
                var que_obj = res.response.questions;
                if (!_.isEmpty(res.response)) {
                  const total_ques_formed =
                    res.response.total_generated_questions;
                  const total_ques_expected = res.response.expected_records;

                  if (total_ques_expected) {
                    if (total_ques_expected !== "N/A") {
                      if (total_ques_formed < total_ques_expected) {
                        this.setState({ lessQuestionsGenerated: true });
                      } else if (total_ques_formed > total_ques_expected) {
                        this.setState({ moreQuestionsGenerated: true });
                      }
                    }
                  }

                  let ques_count = {
                    easyCount: 0,
                    mediumCount: 0,
                    hardCount: 0,
                    descriptiveCount: 0,
                    trueFalseCount: 0,
                    fillUpsCount: 0,
                  };

                  var total_records = res.response.total_easy_mcq;
                  if (category_id == 2)
                    var total_records = res.response.total_medium_mcq;
                  if (category_id == 3)
                    var total_records = res.response.total_hard_mcq;
                  if (category_id == 4)
                    var total_records = res.response.total_true_false;
                  if (category_id == 5)
                    var total_records = res.response.total_descriptive;
                  if (category_id == 6)
                    var total_records = res.response.total_fill_ups;

                  ques_count.easyCount = res.response.total_easy_mcq;
                  ques_count.mediumCount = res.response.total_medium_mcq;
                  ques_count.hardCount = res.response.total_hard_mcq;
                  ques_count.trueFalseCount = res.response.total_true_false;
                  ques_count.descriptiveCount = res.response.total_descriptive;
                  ques_count.fillUpsCount = res.response.total_fill_ups;

                  var que_per_Page = res.response.perPage;
                  var totalPages = Math.ceil(total_records / que_per_Page);

                  this.setState({
                    questions: [...que_obj],
                    questionPaperGenerated: true,
                    ques_count: { ...ques_count },
                    pageNo: res.response.page_no,
                    // questiontypetab: "easymcq",
                    totalPages: totalPages,
                    isQuestionGenerated: true,
                    totalQuestionsFormed: total_ques_formed,
                  });
                  if (this.props.user.update_profile_index == 0) {
                    try {
                      this.scrollToQuestions();
                    } catch (err) {
                      console.log(err);
                    }
                  }
                  await this.sleep(1000);
                  this.setState({
                    showRatingAlert: true,
                    scrollAfterGenerate: true,
                  });
                }
              }
            } else {
              if (res.code == 500) {
                trackEvent("Questions Generation Failed - PDF/Doc");
              }
              this.resetQuestionState();
              if (
                res.code === 111 &&
                this.props.user.plan_details.plan_name === "Free"
              ) {
                this.setState({
                  premiumPromptText: res.message,
                  isShowPremiumPrompt: true,
                });
                if (this.state.showRatingAlert == false) {
                  this.setState({
                    showPremiumPrompt: true,
                  });
                }
              } else {
                notifyError(res.message);
              }
            }
          } catch (err) {
            trackEvent("Questions Generation Failed - PDF/Doc");
            console.log(err);
            this.resetQuestionState();
            if (err.response && err.response.status === 422) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else if (err.response && err.response.status === 401) {
              const { data } = err.response;
              notifyError(data.msg);
              this.props.dispatch(authLogout());
            } else {
              notifyError(MSG.commonApiError);
            }
          }
        }
      } else if (res.code === 429) {
        this.props.dispatch(showRequestInQueuePrompt());
        // notifyWarning(res.message)
      } else {
        notifyError(res.message);

        if (!this.props.user.plan_details.is_new_pricing) {
          if (
            res.code === 111 &&
            this.props.user.plan_details.plan_name === "Free"
          ) {
            this.setState({
              premiumPromptText: res.message,
              isShowPremiumPrompt: true,
            });
            if (this.state.showRatingAlert == false) {
              this.setState({
                showPremiumPrompt: true,
              });
            }
          }
        } else {
          if (res.code == 1199) {
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
              showProgressBar: false,
              contentId: null,
              progress_percent: 0,
            });
            return;
          }

          if (this.props.user.plan_details.active_subscription) {
            if (res.code == 1031) {
              this.setState({
                showRatingAlert: false,
                showTopUp: true,
              });
            }

            let active_plan = this.props.user.plan_details.plan_name;
            if (res.code == 1099) {
              this.setState({
                showRatingAlert: false,
                showAddOn: true,
                activatePlan: this.props.addOnsList.Document[active_plan],
                addOnText: textData.UploadDocumentAddOnText,
                addOnCTAText: textData.AddOnCTAText2.replace(
                  "{price}",
                  this.props.addOnsList.Document[active_plan].currency_symbol +
                    this.props.addOnsList.Document[active_plan].price
                ),
              });
            }
          }
        }
      }
    } catch (err) {
      trackEvent("Questions Generation Failed - PDF/Doc");
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }

    clearInterval(this.intervalId);

    // this.props.dispatch(stopLoader());
    this.setState({
      showProgressBar: false,
      contentId: null,
      progress_percent: 0,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.state.showWordLimit) this.setState({ showWordLimit: false });

    if (this.state.searchTopicWordLimit)
      this.setState({ searchTopicWordLimit: false });

    this.setState({
      [input.name]: input.value,
    });
  };

  handleTitleChange = ({ currentTarget: input }) => {
    if (_.trim(input.value) != "") {
      this.setState({
        titleofQuestion_emsg: "",
      });
    }
    this.setState({
      [input.name]: input.value,
      currentGeneratedQnTitle: input.value,
    });
  };

  handleQuestionUpdate = (question) => {
    // console.log(question);
    this.setState(
      produce(this.state, (draftState) => {
        const index = draftState.questions.findIndex(
          (m) => m.question_id === question.question_id
        );
        draftState.questions[index] = question;
      })
    );
  };

  handlestateforaddquestion = (newquestion) => {
    switch (newquestion.category_type) {
      case 1:
        this.state.ques_count.easyCount += 1;
        break;
      case 2:
        this.state.ques_count.mediumCount += 1;
        break;
      case 3:
        this.state.ques_count.hardCount += 1;
        break;
      case 4:
        this.state.ques_count.trueFalseCount += 1;
        break;
      case 5:
        this.state.ques_count.descriptiveCount += 1;
        break;
      case 6:
        this.state.ques_count.fillUpsCount += 1;
        break;
      default:
        break;
    }
    this.setState({
      questions: [...this.state.questions, newquestion],
    });
  };

  addquestion = () => {
    trackEvent("Add Question Button Clicked", {
      category: this.state.questiontypetab,
      source: this.state.source,
    });

    switch (this.state.questiontypetab) {
      case "easymcq":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "mediummcq":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "hardmcq":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "truefalse":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "descriptive":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "fillups":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handlediscard = (questiontype) => {
    switch (questiontype) {
      case "easymcq":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "mediummcq":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "hardmcq":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "truefalse":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "descriptive":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "fillups":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handleQPRating = async () => {
    if (this.state.questionPaperRating == null) {
      notifyError("Please Rate Question Paper First!");
      return false;
    }
    this.setState({
      showRatingAlert: false,
      feedbackAlert: false,
      showRatingAlert: false,
    });
    var QP_Rating_Parm = {};

    const content_id = this.state.questions[0].content_id;
    if (this.state.ratingComment != "") {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
        comment: this.state.ratingComment,
      };
    } else {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
      };
    }

    // const QP_Rating_Parm = {
    //   content_id: this.state.questionPaperId,
    //   rating: this.state.questionPaperRating,
    //   comment: this.state.ratingComment,
    // }
    //this.props.dispatch(startLoader());
    try {
      const { data: res } = await QuestionPaperRating(QP_Rating_Parm);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          questionPaperRating: null,
          highlightBadEmoji: null,
          highlightNeutralEmoji: null,
          highlightGoodEmoji: null,
          showFeedbackForm: false,
          showRatingAlert: false,
        });
        this.handleRatingPromptCloseEvent();
        //this.setState({ showRatingAlert: false, feedbackAlert: false });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    //this.props.dispatch(stopLoader());
  };

  handleQPRatingChange = (value) => {
    this.setState({
      questionPaperRating: value,
    });
  };

  getRateText = (rate) => {
    if (rate == 1) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Not at All";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 2) {
      document.getElementById("labelFeedbackLabel").innerHTML =
        "To Some Extent";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 3) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Yes, a Lot";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else {
      document.getElementById("labelFeedbackLabel").innerHTML = "";
      document.getElementById("labelFeedbackLabel").style.width = "0";
    }
  };

  handleRatingPromptCloseEvent = () => {
    this.setState({ showRatingAlert: false, feedbackAlert: false });
    if (this.state.isShowPremiumPrompt) {
      this.setState({
        showPremiumPrompt: true,
      });
    } else if (this.state.isShowProfilePopUp) {
      this.setState({
        showUpdateProfilePopUp: true,
        isShowProfilePopUp: false,
      });
    } else return;
  };

  handlePagination = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addPagination(req);
      if (res.success == true) {
        notifySuccess(res.message);
        console.log("res: ", res.response);
        var que_obj = res.response.questions;

        if (!_.isEmpty(que_obj)) {
          let ques_count = {
            easyCount: 0,
            mediumCount: 0,
            hardCount: 0,
            descriptiveCount: 0,
            trueFalseCount: 0,
            fillUpsCount: 0,
          };

          ques_count.easyCount = res.response.total_easy_mcq;
          ques_count.mediumCount = res.response.total_medium_mcq;
          ques_count.hardCount = res.response.total_hard_mcq;
          ques_count.trueFalseCount = res.response.total_true_false;
          ques_count.descriptiveCount = res.response.total_descriptive;
          ques_count.fillUpsCount = res.response.total_fill_ups;

          const que_type = que_obj[0].category_type;
          var que_per_Page = res.response.perPage;

          if (que_type == 1) {
            var que_count = res.response.total_easy_mcq;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 2) {
            var que_count = res.response.total_medium_mcq;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 3) {
            var que_count = res.response.total_hard_mcq;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 4) {
            var que_count = res.response.total_true_false;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 5) {
            var que_count = res.response.total_descriptive;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else if (que_type == 6) {
            var que_count = res.response.total_fill_ups;
            var page_count = Math.ceil(que_count / que_per_Page);
          } else {
            var que_count = 0;
            var page_count = 0;
          }

          this.setState({
            questions: [...que_obj],
            questionPaperGenerated: true,
            ques_count: { ...ques_count },
            pageNo: res.response.page_no,
            totalPages: page_count,
          });
          try {
            this.scrollToQuestions();
          } catch (err) {
            console.log(err);
          }
          await this.sleep(1000);
          this.setState({
            scrollAfterGenerate: true,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  nextPage = () => {
    if (this.state.pageNo == this.state.totalPages) {
      return;
    }
    var pageNo = this.state.pageNo + 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      category_id: que_obj.category_type,
      page_no: pageNo,
    };

    this.handlePagination(req);
  };

  previousPage = () => {
    if (this.state.pageNo == 1) {
      return;
    }
    var pageNo = this.state.pageNo - 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      category_id: que_obj.category_type,
      page_no: pageNo,
    };
    this.handlePagination(req);
  };

  handleQueTypeClick = (e, quetype, category) => {
    e.preventDefault();
    this.setState({ questiontypetab: quetype });
    const ques_count = this.state.ques_count;
    if (category == 1) {
      trackEvent("Easy MCQ Category Clicked");
      if (ques_count.easyCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 2) {
      trackEvent("Medium MCQ Category Clicked");
      if (ques_count.mediumCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 3) {
      trackEvent("Hard MCQ Category Clicked");
      if (ques_count.hardCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 4) {
      trackEvent("True/False Category Clicked");
      if (ques_count.trueFalseCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 5) {
      trackEvent("Descriptive Category Clicked");
      if (ques_count.descriptiveCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 6) {
      trackEvent("Fillups Category Clicked");
      if (ques_count.fillUpsCount == 0) {
        notifySuccess("No Questions Formed");
        return;
      }
    }
    var que_obj = this.state.questions[0];
    var req = {
      content_id: que_obj.content_id,
      category_id: category,
      page_no: 1,
    };

    this.handlePagination(req);
  };

  // Showing the filters selected

  showSelectedFilter = () => {
    return (
      <div className="selected_filters">
        <p className="selected_filter rounded shadow">Easy MCQ</p>
        <p className="selected_filter rounded shadow">Easy MCQ</p>
        <p className="selected_filter rounded shadow">Easy MCQ</p>
      </div>
    );
  };

  handleClose = () => {
    this.props.dispatch(closeReferral());
  };

  updateActivityCount = (e) => {
    if (e.target.className == "dzu-input") {
      var increment = this.state.activity_count + 1;
      this.setState({
        activity_count: increment,
      });
    }
  };

  handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      this.setState({
        data: {
          ...this.state.data,
          contact_number: value,
          formatted_phone_number: formattedValue,
          country_code: data.countryCode,
          dial_code: "+" + data.dialCode,
        },
      });
    }
  };

  handleUpdateProfileChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };

  CancelUpdateProfile = async () => {
    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails({
        ask_later: 1,
      });
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  UpdateProfileSubmit = async () => {
    if (this.state.profession == "") {
      notifyError("Please select an option under profession section");
      return;
    } else if (
      this.state.profession == "Others" &&
      this.state.profession_other == ""
    ) {
      notifyError("Please enter your profession");
      return;
    } else if (this.state.source_from == "") {
      notifyError("Please select an option under source section");
      return;
    } else if (
      this.state.source_from == "Others" &&
      this.state.source_from_other == ""
    ) {
      notifyError("Please enter your profession");
      return;
    } else if (this.state.data.contact_number == "") {
      notifyError("Please enter your phone details");
      return;
    }

    let reqObj = this.state.data;
    let req = {
      ...reqObj,
      profession: this.state.profession,
      profession_other: this.state.profession_other,
      source_from: this.state.source_from,
      source_from_other: this.state.source_from_other,
    };
    req.contact_number = req.contact_number.substring(req.dial_code.length - 1);
    // }

    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails(req);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  redirectToBloom = async () => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await btUpdateToken();
      if (res.success == true) {
        window.open(
          `${process.env.REACT_APP_HOTSPREPAI_APP_URL}/sso?token=${res.response.token}`
        );
      } else {
        notifyError("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  handleGenerateAnyway = () => {
    sessionStorage.removeItem("show_initial_text");
    this.setState({ newUser: false, showEditContentPopUp: false }, () =>
      this.handleGenerateQuestion()
    );
    this.props.dispatch(
      showDefaultContent({
        title: "",
        content: ``,
        flag: true,
      })
    );
  };

  handleUpdateContent = () => {
    sessionStorage.removeItem("show_initial_text");
    this.setState({
      newUser: false,
      showEditContentPopUp: false,
      // currentGeneratedQnTitle: this.state.titleofQuestion,
      titleofQuestion: "",
      contentForQuestions: "",
    });

    const position = document.getElementById("titleofQuestion").offsetTop;
    window.scrollTo(0, position);
    document.getElementById("titleofQuestion").focus();
    this.props.dispatch(
      showDefaultContent({
        title: "",
        content: ``,
        flag: true,
      })
    );
  };

  handleCreateTest = async () => {
    if (!this.props.user.plan_details.is_group_assessment) {
      return;
    }
    this.props.dispatch(startLoader());
    var content_id = this.state.questions[0].content_id;
    try {
      const { data: res } = await createTest({
        content_id: content_id,
      });
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.history.push({
          pathname: "/test-preview",
          state: {
            content_id: content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  beforeAssessment = () => {
    if (!this.props.user.plan_details.is_self_assessment) {
      return;
    }
    const { content_id, topic } = this.state.questions[0];
    this.setState({
      showDurationAlert: true,
      currentContentId: content_id,
      currentTopicName: topic,
    });
  };

  callbackFunc = (topic, show) => {
    this.setState({ showDurationAlert: false, currentTopicName: topic });
  };

  displayStripe = async (plan) => {
    if (plan.plan_name === "Free") {
      this.props.history.replace("/plans");
      return;
    }

    this.setState({
      showPrompt: false,
      showEnterprisePrompt: false,
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(startLoader());

    try {
      if (plan.plan_name === "Free") {
        var api_name = purchaseFreePlan;
        var req = {
          plan_id: plan.plan_id,
        };
      } else {
        var api_name = purchaseAddOns;
        var req = {
          add_on_id: plan.plan_id,
        };
      }

      const { data: res } = await api_name(req);
      if (res.success == true) {
        notifySuccess(res.message);
        // this.props.dispatch(setSelectedPlan(plan));
        const clientSecret = res.response.clientSecret;
        var payment_details = {
          clientSecret: clientSecret,
          amount: res.response.amount,
          is_coupon_applied: res.response.is_coupon_applied,
          coupon: res.response.coupon,
          currency: res.response.currency,
          // price_usd: plan.price,
          // plan_name: plan.plan_name,
          // currency:
          //   this.state.plans["Monthly"][this.state.questionPaperCountSolo][
          //     "currency"
          //   ],
          // team_size: this.state.team_size,
        };
        this.props.dispatch(setSelectedPlan(payment_details));
        if (clientSecret) {
          this.setState({
            // selectedPlan: { ...plan },
            stripePromise: loadStripe(this.state.stripeKey),
            showStripeForm: true,
            stripeClientSecret: clientSecret,
            showAddOn: false,
          });
          // this.props.dispatch(
          //   showReferral({
          //     title: "Want to extend your plan for free?",
          //     content:
          //       "Invite your friends and get 7 days of Premium Plan added",
          //   })
          // );
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  questionPaperTopUPCallBack = (data) => {
    if (data.hidePopUp) {
      this.setState({
        showTopUp: !data.hidePopUp,
      });
    }
  };
  handleDownloadFile = async (type, answer = 0) => {
    const item = {
      topic: this.state.currentGeneratedQnTitle,
      content_id: this.state.questions[0]?.content_id,
    };
    if (type == "xlsx") {
      answer = 0;
    }
    if (
      (type == "docx" &&
        !this.props.user.plan_details.download_doc_is_allowed) ||
      (type == "xlsx" &&
        !this.props.user.plan_details.download_excel_is_allowed)
    ) {
      return;
    }
    /*
    if (type == "pdf" && !item.plan_details.download_pdf_is_allowed) {
      notifyError("Download not allowed for this content in your plan");
      return;
    }
    if (type == "docx" && !item.plan_details.download_pdf_is_allowed) {
      notifyError("Download not allowed for this content in your plan");
      return;
    }
    if (type == "xlsx" && !item.plan_details.download_pdf_is_allowed) {
      notifyError("Download not allowed for this content in your plan");
      return;
    } */

    var add_on_name = null;

    this.props.dispatch(startLoader());
    if (type == "pdf") {
      trackEvent("Download PDF - Preview Question Page");
      var inputFiletype = "pdf";
      var fileName = item.topic + ".pdf";
      var mimeType = "application/pdf";
    } else if (type == "docx") {
      trackEvent("Download Doc - Preview Question Page");
      var inputFiletype = "word";
      var fileName = item.topic + ".docx";
      var mimeType = "application/msword";
      add_on_name = "Download-Doc";
    } else if (type == "xlsx") {
      trackEvent("Download Excel - Preview Question Page");
      var inputFiletype = "excel";
      var fileName = item.topic + ".xlsx";
      var mimeType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      add_on_name = "Download-Excel";
    } else if (type == "json") {
      trackEvent("Download Json - Preview Question Page");
      var inputFiletype = "json";
      var fileName = item.topic + ".json";
      var mimeType = "application/json";
    }

    try {
      const { data: respose } = await validationForDownloadQuePaper({
        content_id: item.content_id,
        filetype: inputFiletype,
        isDownload: 1,
      });
      if (respose.success == true) {
        /*if (type == "pdf") { */
        if (respose.code == 1111) {
          this.props.dispatch(fetchUser());
        } else if (respose.code === 221) {
          notifyInfo(respose.message);
          this.props.dispatch(stopLoader());
          return;
        }
        try {
          const res = await downloadQuestionPaper({
            content_id: item.content_id,
            filetype: inputFiletype,
            answer,
          });
          if (res.status != 200) {
            notifyError(res.message);
          } else {
            var blob = new Blob([res.data], { type: mimeType });
            saveAs(blob, fileName);
          }
        } catch (err) {
          console.log("err", err);
          notifyError(MSG.commonApiError);
        }
      } else {
        notifyError(respose.message);
        if (this.props.user.plan_details.active_subscription) {
          let active_plan = this.props.user.plan_details.plan_name;
          // 1099 code means purchase add ons to use this feature.
          if (respose.code == 1099) {
            this.setState({
              showAddOn: true,
              activatePlan: this.props.addOnsList[add_on_name][active_plan],
              addOnText: textData.DownloadDocumentAddOnText.replace(
                "{price}",
                this.props.addOnsList[add_on_name][active_plan]
                  .currency_symbol +
                  this.props.addOnsList[add_on_name][active_plan].price
              ),
              addOnCTAText: textData.AddOnCTAText3,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }

    this.props.dispatch(stopLoader());
  };

  handleSubsetCallBack = async (data) => {
    if (!data.showPopUp) {
      this.setState({
        showSetsPopUp: data.showPopUp,
      });
    }
  };

  handleSetState = (state) => {
    this.setState(state);
  };
  getUrlContant = async (val) => {
    console.log("hello");
    if (this.state.selectedWebURL != "") {
      this.props.dispatch(startLoader());
      try {
        var urlFormData = new FormData();
        urlFormData.append("web_url", this.state.selectedWebURL);
        urlFormData.append("generateContant", "");
        console.log("here trihher");
        const { data: res } = await generateQuestionWebUrl(urlFormData);
        console.log("result", res);
        if (res.success == true) {
          const content = res.response;
          this.setState({
            // options: ldpas,
            selectedWebURL: this.state.selectedWebURL,
            webUrlContant: content,
          });
        } else if (res.code === 429) {
          this.props.dispatch(showRequestInQueuePrompt());
          // notifyWarning(res.message)
        } else {
          notifyError(res.message);
          this.setState({
            webUrlContant: null,
          });
        }
      } catch (err) {
        notifyError("failed to extract the contant");
      }
      this.props.dispatch(stopLoader());
    } else {
      notifyError("Please enter Url to search");
    }
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.getUrlContant();
    }
  };

  handleEnterTopic = (e) => {
    if (e.key === "Enter") {
      this.getContent();
    }
  };

  getContent = async (val) => {
    if (this.state.tmpselectedWikiTopic != "") {
      this.props.dispatch(startLoader());
      try {
        var wikiFormData = new FormData();
        wikiFormData.append("wiki-topic", this.state.tmpselectedWikiTopic);
        wikiFormData.append("generateQA", "");
        const { data: res } = await GQSearchTopic(wikiFormData);
        console.log("hello", res);
        if (res.success == true) {
          const content = res.response.content;
          console.log(content);
          this.setState({
            // options: ldpas,
            wikiSearchInput: this.state.tmpselectedWikiTopic,
            wikiContent: content,
          });
        } else if (res.code === 429) {
          this.props.dispatch(showRequestInQueuePrompt());
          // notifyWarning(res.message)
        }
      } catch (err) {
        console.log(err);
      }
      this.props.dispatch(stopLoader());
    } else {
      notifyError("Please enter topic to search");
    }
  };
  handleVisualsProcess = async () => {
    if (this.state.isVisualText) {
      await this.handleGenerateQuestion("isViuals");
    } else if (this.state.isVisualWeb) {
      await this.handleGenerateUrl("isViuals");
    } else if (this.state.isvisualTopic) {
      await this.handleGenerateQuestionForWiki("isViuals");
    } else if (this.state.isVisualVideo) {
      await this.handleGenerateQuestionFromVideo("isViuals");
    } else if (this.state.isVisualDoc) {
      await this.handleGenerateQuestionFromPdfDoc("isViuals");
    }
  };

  render() {
    let que_number_start_pt = this.state.pageNo * 10 - 10; // ques number for first question of every page say 1 for page one 11 for page 2
    let easymcq = que_number_start_pt;
    let mediummcq = que_number_start_pt;
    let hardmcq = que_number_start_pt;
    let true_false = que_number_start_pt;
    let fillups = que_number_start_pt;
    let descriptive = que_number_start_pt;
    let is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;

    return (
      <React.Fragment>
        <Prompt
          when={this.state.leavePageEvent}
          message={() =>
            trackEvent("Leaving Generate Question Page", {
              source: this.state.source,
            })
          }
        />
        {this.props.user.email ? (
          <ProductFruits
            workspaceCode="jMjcOQ8ZH4KmfIKv"
            language="en"
            user={{
              username: this.props.user.name,
              email: this.props.user.email,
              firstname: this.props.user.first_name,
              lastname: this.props.user.last_name,
              signUpAt: this.props.user.sign_up_at,
              props: { question_mode: this.props.user.question_mode },
            }}
          />
        ) : (
          ""
        )}

        <Tooltip title="Coming Soon" id="global" />
        <div
          className={classNames(
            styles["mobile-height-cover"],
            "height-cover bg-e5"
          )}
        >
          {/* <div className="row countdwn-bt">
            <p className="countdown_msg_bt">
              Click here to user Blooms Taxanomy
              <Link
                className="learnmorebtn-bt"
                to={{
                  pathname:
                    "https://www.prepai.io/higher-order-thinking-question-maker/",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Link>
            </p>
          </div> */}
          {/* <div class="row countdwn-bt">
            <div class="col-md-6 text_msg">
              <p class="countdown_msg_bt">
                Rolling Out Soon: Bloom’s Taxonomy on PrepAI - Generate higher
                order thinking questions
                <a href="#" class="w-btn us-btn-style_3 learnmorebtn-bt">
                  {" "}
                  Learn More
                </a>
              </p>
            </div>
          </div> */}

          {this.state.showBar && (
            <div className="row col-md-12 countdwn-home-bt">
              <div className="col-md-8">
                <p className="countdown_msg_bt">
                  Generate higher order thinking questions as per the Bloom's
                  Taxonomy
                </p>
              </div>
              <div className="col-md-4 countdown_msg_bt">
                <span
                  onClick={(e) => {
                    this.setState({ showBar: !this.state.showBar });
                  }}
                  style={{ padding: "15px", cursor: "pointer" }}
                >
                  Dismiss
                </span>
                {/* <Link
                  className="learnmorebtn-bt"
                  // to={{ pathname: "https://hots.prepai.io/" }}
                  to={{ pathname: "http://localhost:3000/generate-questions" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Let's go
                </Link> */}
                <span
                  className="learnmorebtn-bt"
                  onClick={this.redirectToBloom}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Let's go{" "}
                </span>
              </div>
            </div>
          )}
          <div className="bg--overlay--top"></div>

          {this.state.showProgressBar && (
            <div>
              <div className="overlay"></div>
              <ProgressBar
                progress={this.state.progress_percent}
                content_id={this.state.contentId}
              ></ProgressBar>
            </div>
          )}

          <div
            className={classNames(
              styles["container-custom-margin"],
              "container mb-4 position-relative"
            )}
          >
            <div
              className={classNames(
                styles["generate--bg--title"],
                "generate--bg--title"
              )}
            >
              <h3>Generate Interactive Quiz Instantly</h3>
              <span>Most Advanced Test Generator</span>
            </div>
            <div>
              {/* <Toggle blooms={false} /> */}
              <ToggleForGQ tab="Standard" />
            </div>
            <GenerateQuestionStdHots
              handleCheckbox={this.handleCheckBox}
              state={this.state}
              setState={this.handleSetState}
              placeholders={{
                title: "e.g. Chapter Name, Topic Name, Section, etc.",
                content:
                  "Type/Paste your content (minimum 50 words) here to generate questions...",
                searchTopic: `Enter Topic Name & Hold On`,
                wiki: "Extracted Content will be shown here. Edit, Remove or Add More Content.",
                webUrl:
                  "Enter the Web URL of the content you want to extract questions from",
                webUrlContant: "Please Wait for the Extracted Contant",
              }}
              customPageHandler={this.customPageHandler}
              endTimeStampHandler={this.endTimeStampHandler}
              getContent={this.getContent}
              getDocPdfFromEvent={this.getDocPdfFromEvent}
              getFilesFromEvent={this.getFilesFromEvent}
              handleChange={this.handleChange}
              handleClearCustomDuration={this.handleClearCustomDuration}
              handleClearCustomPages={this.handleClearCustomPages}
              handleClearCustomTopic={this.handleClearCustomTopic}
              handleClearCustomWiki={this.handleClearCustomWiki}
              handleGenerateQuestions={this.handleGenerateQuestion}
              handlePopUpOpen={this.handlePopUpOpen}
              handleWikiTopicBlur={this.handleWikiTopicBlur}
              handleWikiTopicFocus={this.handleWikiTopicFocus}
              onInputChange={this.onInputChange}
              onInputChangeWiki={this.onInputChangeWiki}
              handlePdfDocFileStatusChange={this.handlePdfDocFileStatusChange}
              handleDocPage={this.handleDocPage}
              handleFileStatusChange={this.handleFileStatusChange}
              isCustomDurationHandler={this.isCustomDurationHandler}
              startTimeStampHandler={this.startTimeStampHandler}
              handlePopUpClose={this.handlePopUpClose}
              handleWikiTopicSelect={this.handleWikiTopicSelect}
              handleEnter={this.handleEnter}
              handleTitleChange={this.handleTitleChange}
              videoExtractButtonContent={this.videoExtractButtonContent}
              is_bloom={false}
              handleGenerateQuestion={this.handleGenerateQuestion}
              handleGenerateQuestionForWiki={this.handleGenerateQuestionForWiki}
              handleGenerateQuestionForDoc={this.handleGenerateQuestionForDoc}
              handleGenerateQuestionFromPdfDoc={
                this.handleGenerateQuestionFromPdfDoc
              }
              handleGenerateQuestionForFillup={
                this.handleGenerateQuestionForFillup
              }
              handleGenerateQuestionForTrueFalse={
                this.handleGenerateQuestionForTrueFalse
              }
              handleGenerateQuestionForDescriptive={
                this.handleGenerateQuestionForDescriptive
              }
              handleGenerateQuestionForEasymcq={
                this.handleGenerateQuestionForEasymcq
              }
              handleGenerateQuestionForMediummcq={
                this.handleGenerateQuestionForMediummcq
              }
              handleGenerateQuestionForHardmcq={
                this.handleGenerateQuestionForHardmcq
              }
              handleSubmit={this.handleSubmit}
              tabChanged={this.tabChanged}
              redirectToPlansPage={this.redirectToPlansPage}
              is_new_pricing={is_new_pricing}
              props={this.props}
              handleSubsetCallBack={this.handleSubsetCallBack}
              startTypingTime={this.startTypingTime}
              updateActivityCount={this.updateActivityCount}
              key={this.state.questionPaperGenerated}
              driveVideo={this.state.driveVideo}
              getUrlContant={this.getUrlContant}
              onUrlChange={this.onUrlChange}
              handleGenerateUrl={this.handleGenerateUrl}
              handleEnterTopic={this.handleEnterTopic}
              // handleWikiTopicSelect={this.handleWikiTopicSelect}
              // handleWikiTopicFocus={this.handleWikiTopicFocus}
              // handleWikiTopicBlur={this.handleWikiTopicBlur}
            />
            <div id="scroll-to-div"></div>
          </div>
        </div>

        {this.state.questionPaperGenerated && (
          <div className="section--padding bg-e5" id="generated_questions">
            <div
              className={classNames(
                "container",
                styles["demo-custom-container"]
              )}
            >
              <div className="row">
                <div
                  className="offset-md-1 mb-4"
                  style={{ width: "100%", marginLeft: "1.333333%" }}
                >
                  <div
                    className={classNames(
                      styles["generated-questions-top-section"],
                      "row"
                    )}
                  >
                    <div className="col-md-4">
                      <p
                        className={classNames(
                          styles["section-generated-title-responsive"],
                          "section-generated-title"
                        )}
                        // style={{ marginLeft: "29.5%" }}
                      >
                        Generated Questions
                      </p>
                    </div>
                    <div
                      className={classNames(
                        styles["front-action-buttons-responsive"],
                        "col-md-8 front-action-buttons"
                      )}
                    >
                      <div className="nav-item dropdown fl-center">
                        <a
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "nav-link dropdown-toggle dropdown-title-front"
                          )}
                          href="/#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Conduct Test
                        </a>
                        <div
                          className={classNames(
                            styles["conduct-test-dropdown"],
                            "dropdown-menu navdrop--menu dropdown-main"
                          )}
                          aria-labelledby="navbarDropdown"
                        >
                          <Link
                            to="#"
                            onClick={this.beforeAssessment}
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              `dropdown-item ${
                                !_.isEmpty(this.props.user) &&
                                !this.props.user.plan_details.is_self_assessment
                                  ? "sm-disabled"
                                  : ""
                              }`
                            )}
                          >
                            For Self
                          </Link>
                          <Link
                            to="#"
                            onClick={this.handleCreateTest}
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              `dropdown-item ${
                                !_.isEmpty(this.props.user) &&
                                !this.props.user.plan_details
                                  .is_group_assessment
                                  ? "sm-disabled"
                                  : ""
                              }`
                            )}
                          >
                            For Others
                            {/* {is_new_pricing === 1 && (
                              <img
                                src="img/crown.jpg"
                                alt="icon"
                                width="30"
                                height="30"
                                style={{
                                  position: "absolute",
                                  marginTop: "-12px",
                                  marginLeft: "-7px",
                                }}
                              />
                            )} */}
                          </Link>
                        </div>
                      </div>
                      <div
                        className=""
                        style={
                          {
                            // paddingRight: "2%",
                          }
                        }
                      >
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "preview--paper--btn"
                          )}
                          to="#"
                          // to={{
                          //   pathname: "/question-paper-preview",
                          //   state: this.state.questions[0],
                          // }}
                          // onClick={(e) =>
                          //   trackEvent("Preview Question Paper Clicked", {
                          //     source: this.state.source,
                          //   })
                          // }
                          onClick={() => this.setState({ showPreview: true })}
                        >
                          Preview Quiz
                        </Link>
                      </div>
                      <div
                        className=""
                        style={
                          {
                            // paddingRight: "2%",
                          }
                        }
                      >
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "preview--paper--btn"
                          )}
                          to={"#"}
                          onClick={(e) => {
                            this.setState({ showSetsPopUp: true });
                          }}
                        >
                          Generate Sets
                        </Link>
                        {this.state.showSetsPopUp && (
                          <QuestionPaperSetsStandard
                            callBack={this.handleSubsetCallBack}
                            data={{
                              show: this.state.showSetsPopUp,
                              content_id: this.state.questions[0]?.content_id,
                              que_count: this.state.ques_count,
                            }}
                          ></QuestionPaperSetsStandard>
                        )}
                      </div>
                      <div className="">
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "preview--paper--btn"
                          )}
                          to={"#"}
                          onClick={(e) => {
                            this.setState({ showDownloadPopUp: true });
                          }}
                        >
                          Download
                        </Link>
                        <DonwloadQuestionPaper
                          showDownloadPopUp={this.state.showDownloadPopUp}
                          closeDownloadPopUp={() =>
                            this.setState({ showDownloadPopUp: false })
                          }
                          downloadAllowed={this.state.currentItem}
                          downloadQuestionPaper={this.handleDownloadFile}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="left-widget-area">
                    <div
                      className={classNames(
                        styles["category-widget-responsive"],
                        "category-widget"
                      )}
                    >
                      <p
                        className={classNames(styles["categories-title"])}
                        style={{
                          marginBottom:
                            window.innerWidth > 767
                              ? "1rem"
                              : this.state.categoryAccordion
                              ? "1rem"
                              : "0rem",
                        }}
                      >
                        Categories
                        {window.innerWidth <= 767 && (
                          <>
                            {this.state.categoryAccordion ? (
                              <ExpandLess
                                onClick={() =>
                                  this.setState({
                                    categoryAccordion:
                                      !this.state.categoryAccordion,
                                  })
                                }
                              />
                            ) : (
                              <ExpandMore
                                onClick={() =>
                                  this.setState({
                                    categoryAccordion:
                                      !this.state.categoryAccordion,
                                  })
                                }
                              />
                            )}
                          </>
                        )}
                      </p>
                      {window.innerWidth > 767 ? (
                        <CategoryBoxStd
                          handleQueTypeClick={this.handleQueTypeClick}
                          is_new_pricing={is_new_pricing}
                          state={this.state}
                        />
                      ) : (
                        this.state.categoryAccordion && (
                          <CategoryBoxStd
                            handleQueTypeClick={this.handleQueTypeClick}
                            is_new_pricing={is_new_pricing}
                            state={this.state}
                          />
                        )
                      )}
                    </div>
                    <button
                      className={classNames(
                        styles["add-quetsion--btn"],
                        `add-quetsion--btn w-100 ${
                          !_.isEmpty(this.props.user) &&
                          !this.props.user.plan_details.is_add_question
                            ? "sm-disabled"
                            : ""
                        }`
                      )}
                      // className="add-quetsion--btn w-100"
                      // role="button"
                      type="button"
                      // onClick={this.addquestion}
                      onClick={this.handleAddQuestionOnClick}
                      disabled={
                        !_.isEmpty(this.props.user) &&
                        !this.props.user.plan_details.is_add_question
                      }
                    >
                      <svg
                        className="mr-2"
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                      >
                        <path
                          d="M8.38021 1V14M1.8125 7.5H14.9479"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                      </svg>
                      Add Question
                      {/* {is_new_pricing === 1 && (
                        <img
                          src="img/crown.jpg"
                          alt="icon"
                          width="30"
                          height="30"
                          style={{
                            position: "absolute",
                            marginTop: "-12px",
                            marginLeft: "-5px",
                          }}
                        />
                      )} */}
                    </button>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-easy"
                      role="tabpanel"
                      aria-labelledby="v-pills-easy-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.easyCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 1) {
                            easymcq = easymcq + 1;
                            return (
                              <EasyMCQ
                                key={`question_${question.question_id}`}
                                question={question}
                                count={easymcq}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                // data={{ source: this.state.source }}
                              />
                            );
                          }
                        })}
                        {this.state.addeasymcq == true ? (
                          <div>
                            <br />
                            <Addeasymcq
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Addeasymcq>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                      {/* Easy MCQ Pagination Section */}
                      {this.state.ques_count.easyCount != 0 && (
                        <div className="each-question-wrapper-box-pagination">
                          <div
                            className={classNames(
                              styles["flex-nowrap"],
                              "row align-items-center"
                            )}
                          >
                            <div className="col-md-4">
                              <p
                                className={classNames(
                                  styles["pagination-text"],
                                  "showing-page-results"
                                )}
                              >
                                Showing {this.state.pageNo}-
                                {this.state.totalPages} of{" "}
                                {this.state.totalPages} items
                              </p>
                            </div>
                            <div className="col-md-8">
                              <ul className="list-unstyled-pagination">
                                <li
                                  className={`prev-navigation show-pointer ${
                                    this.state.pageNo == 1 ? "sm-disabled" : ""
                                  }`}
                                  onClick={this.previousPage}
                                >
                                  <a
                                    className={
                                      this.state.pageNo == 1
                                        ? "sm-disabled"
                                        : ""
                                    }
                                  >
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                        stroke={
                                          this.state.pageNo == 1
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                                <li className="page-count-navigation">
                                  <a>{this.state.pageNo}</a>
                                </li>
                                <li
                                  className={`next-navigation show-pointer ${
                                    this.state.pageNo == this.state.totalPages
                                      ? "sm-disabled"
                                      : ""
                                  }`}
                                  onClick={this.nextPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                        stroke={
                                          this.state.pageNo ==
                                          this.state.totalPages
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-medium"
                      role="tabpanel"
                      aria-labelledby="v-pills-medium-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.mediumCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 2) {
                            mediummcq = mediummcq + 1;
                            return (
                              <MediumMCQ
                                key={`question_${question.question_id}`}
                                question={question}
                                count={mediummcq}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                // data={{ source: this.state.source }}
                              />
                            );
                          }
                        })}
                        {this.state.addmediummcq == true ? (
                          <div>
                            <br />
                            <Addmediummcq
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Addmediummcq>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>

                      {/* Medium MCQ Pagination Section */}
                      {this.state.ques_count.mediumCount != 0 && (
                        <div className="each-question-wrapper-box-pagination">
                          <div
                            className={classNames(
                              styles["flex-nowrap"],
                              "row align-items-center"
                            )}
                          >
                            <div className="col-md-4">
                              <p
                                className={classNames(
                                  styles["pagination-text"],
                                  "showing-page-results"
                                )}
                              >
                                Showing {this.state.pageNo}-
                                {this.state.totalPages} of{" "}
                                {this.state.totalPages} items
                              </p>
                            </div>
                            <div className="col-md-8">
                              <ul className="list-unstyled-pagination">
                                <li
                                  className={`prev-navigation show-pointer ${
                                    this.state.pageNo == 1 ? "sm-disabled" : ""
                                  }`}
                                  onClick={this.previousPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                        stroke={
                                          this.state.pageNo == 1
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                                <li className="page-count-navigation">
                                  <a>{this.state.pageNo}</a>
                                </li>
                                <li
                                  className={`next-navigation show-pointer ${
                                    this.state.pageNo == this.state.totalPages
                                      ? "sm-disabled"
                                      : ""
                                  }`}
                                  onClick={this.nextPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                        stroke={
                                          this.state.pageNo ==
                                          this.state.totalPages
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-hard"
                      role="tabpanel"
                      aria-labelledby="v-pills-hard-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.hardCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 3) {
                            hardmcq = hardmcq + 1;
                            return (
                              <HardMCQ
                                key={`question_${question.question_id}`}
                                question={question}
                                count={hardmcq}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                // data={{ source: this.state.source }}
                              />
                            );
                          }
                        })}
                        {this.state.addhardmcq == true ? (
                          <div>
                            <br />
                            <Addhardmcq
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Addhardmcq>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>

                      {/* Hard MCQ Pagination Section */}
                      {this.state.ques_count.hardCount != 0 && (
                        <div className="each-question-wrapper-box-pagination">
                          <div
                            className={classNames(
                              styles["flex-nowrap"],
                              "row align-items-center"
                            )}
                          >
                            <div className="col-md-4">
                              <p
                                className={classNames(
                                  styles["pagination-text"],
                                  "showing-page-results"
                                )}
                              >
                                Showing {this.state.pageNo}-
                                {this.state.totalPages} of{" "}
                                {this.state.totalPages} items
                              </p>
                            </div>
                            <div className="col-md-8">
                              <ul className="list-unstyled-pagination">
                                <li
                                  className={`prev-navigation show-pointer ${
                                    this.state.pageNo == 1 ? "sm-disabled" : ""
                                  }`}
                                  onClick={this.previousPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                        stroke={
                                          this.state.pageNo == 1
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                                <li className="page-count-navigation">
                                  <a>{this.state.pageNo}</a>
                                </li>
                                <li
                                  className={`next-navigation show-pointer ${
                                    this.state.pageNo == this.state.totalPages
                                      ? "sm-disabled"
                                      : ""
                                  }`}
                                  onClick={this.nextPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                        stroke={
                                          this.state.pageNo ==
                                          this.state.totalPages
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-truefalse"
                      role="tabpanel"
                      aria-labelledby="v-pills-truefalse-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.trueFalseCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 4) {
                            true_false = true_false + 1;
                            return (
                              <TrueFalse
                                key={`question_${question.question_id}`}
                                question={question}
                                count={true_false}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                // data={{ source: this.state.source }}
                              />
                            );
                          }
                        })}
                        {this.state.addtruefalse == true ? (
                          <div>
                            <br />
                            <Truefalse
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Truefalse>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>

                      {/* True/False Pagination Section */}
                      {this.state.ques_count.trueFalseCount != 0 && (
                        <div className="each-question-wrapper-box-pagination">
                          <div
                            className={classNames(
                              styles["flex-nowrap"],
                              "row align-items-center"
                            )}
                          >
                            <div className="col-md-4">
                              <p
                                className={classNames(
                                  styles["pagination-text"],
                                  "showing-page-results"
                                )}
                              >
                                Showing {this.state.pageNo}-
                                {this.state.totalPages} of{" "}
                                {this.state.totalPages} items
                              </p>
                            </div>
                            <div className="col-md-8">
                              <ul className="list-unstyled-pagination">
                                <li
                                  className={`prev-navigation show-pointer ${
                                    this.state.pageNo == 1 ? "sm-disabled" : ""
                                  }`}
                                  onClick={this.previousPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                        stroke={
                                          this.state.pageNo == 1
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                                <li className="page-count-navigation">
                                  <a>{this.state.pageNo}</a>
                                </li>
                                <li
                                  className={`next-navigation show-pointer ${
                                    this.state.pageNo == this.state.totalPages
                                      ? "sm-disabled"
                                      : ""
                                  }`}
                                  onClick={this.nextPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                        stroke={
                                          this.state.pageNo ==
                                          this.state.totalPages
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-Descriptive"
                      role="tabpanel"
                      aria-labelledby="v-pills-Descriptive-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.descriptiveCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 5) {
                            descriptive = descriptive + 1;
                            return (
                              <Descriptive
                                key={`question_${question.question_id}`}
                                question={question}
                                count={descriptive}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                // data={{ source: this.state.source }}
                              />
                            );
                          }
                        })}
                        {this.state.adddescriptive == true ? (
                          <div>
                            <br />
                            <AddDescriptive
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></AddDescriptive>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                      {/* Descriptive Type Pagination Section */}
                      {this.state.ques_count.descriptiveCount != 0 && (
                        <div className="each-question-wrapper-box-pagination">
                          <div
                            className={classNames(
                              styles["flex-nowrap"],
                              "row align-items-center"
                            )}
                          >
                            <div className="col-md-4">
                              <p
                                className={classNames(
                                  styles["pagination-text"],
                                  "showing-page-results"
                                )}
                              >
                                Showing {this.state.pageNo}-
                                {this.state.totalPages} of{" "}
                                {this.state.totalPages} items
                              </p>
                            </div>
                            <div className="col-md-8">
                              <ul className="list-unstyled-pagination">
                                <li
                                  className={`prev-navigation show-pointer ${
                                    this.state.pageNo == 1 ? "sm-disabled" : ""
                                  }`}
                                  onClick={this.previousPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                        stroke={
                                          this.state.pageNo == 1
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                                <li className="page-count-navigation">
                                  <a>{this.state.pageNo}</a>
                                </li>
                                <li
                                  className={`next-navigation show-pointer ${
                                    this.state.pageNo == this.state.totalPages
                                      ? "sm-disabled"
                                      : ""
                                  }`}
                                  onClick={this.nextPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                        stroke={
                                          this.state.pageNo ==
                                          this.state.totalPages
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-Fillups"
                      role="tabpanel"
                      aria-labelledby="v-pills-Fillups-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.fillUpsCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 6) {
                            fillups = fillups + 1;
                            return (
                              <FillUpsMCQ
                                key={`question_${question.question_id}`}
                                question={question}
                                count={fillups}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                // data={{ source: this.state.source }}
                              />
                            );
                          }
                        })}
                        {this.state.addfillups == true ? (
                          <div>
                            <br />
                            <Fillups
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Fillups>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>

                      {/* Fillups Pagination Section */}
                      {this.state.ques_count.fillUpsCount != 0 && (
                        <div className="each-question-wrapper-box-pagination">
                          <div
                            className={classNames(
                              styles["flex-nowrap"],
                              "row align-items-center"
                            )}
                          >
                            <div className="col-md-4">
                              <p
                                className={classNames(
                                  styles["pagination-text"],
                                  "showing-page-results"
                                )}
                              >
                                Showing {this.state.pageNo}-
                                {this.state.totalPages} of{" "}
                                {this.state.totalPages} items
                              </p>
                            </div>
                            <div className="col-md-8">
                              <ul className="list-unstyled-pagination">
                                <li
                                  className={`prev-navigation show-pointer ${
                                    this.state.pageNo == 1 ? "sm-disabled" : ""
                                  }`}
                                  onClick={this.previousPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                        stroke={
                                          this.state.pageNo == 1
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                                <li className="page-count-navigation">
                                  <a>{this.state.pageNo}</a>
                                </li>
                                <li
                                  className={`next-navigation show-pointer ${
                                    this.state.pageNo == this.state.totalPages
                                      ? "sm-disabled"
                                      : ""
                                  }`}
                                  onClick={this.nextPage}
                                >
                                  <a>
                                    <svg
                                      width="7"
                                      height="12"
                                      viewBox="0 0 7 12"
                                      fill="none"
                                    >
                                      <path
                                        d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                        stroke={
                                          this.state.pageNo ==
                                          this.state.totalPages
                                            ? "#B7CCEB"
                                            : "#000000"
                                        }
                                        strokeWidth="1.3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showRatingAlert && !this.state.showProgressBar && (
          <div className="question-generate-tab-outer maxw-820 feedback">
            <button
              type="button"
              className="close close_buttons"
              aria-label="Close"
              style={{ outline: "none" }}
              onClick={() =>
                this.setState({
                  showFeedbackForm: false,
                  showRatingAlert: false,
                })
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="swal-titile">
              <h4>
                {/* <span>Your Question Paper is being Generated.</span>
                    <br />
                    <span>
                      Meanwhile, what do you think of your recent Question
                      Paper?{" "}
                    </span> */}
                <span>Found this question paper helpful?</span>
              </h4>
            </div>
            <div className="rate--question large-star1">
              <span className="feedbackLabel">
                <span id="labelFeedbackLabel"></span>
              </span>
              <div className="line-height-0 d-flex justify-content-center">
                <div id="bad" className="emoji-scale">
                  <span className="describeEmoji">Not at All</span>
                  <i
                    className={`emoji ${
                      this.state.questionPaperRating == 1 ? "fas" : "far"
                    } fa-frown highlight ${this.state.highlightBadEmoji}`}
                    onClick={() =>
                      this.setState({
                        questionPaperRating: 1,
                        highlightNeutralEmoji: null,
                        highlightGoodEmoji: null,
                        highlightBadEmoji: "highlight-emoji",
                      })
                    }
                  ></i>
                </div>
                <div id="neutral" className="emoji-scale">
                  <span className="describeEmoji" style={{ marginLeft: "0px" }}>
                    To Some Extent
                  </span>
                  <i
                    className={`emoji ${
                      this.state.questionPaperRating == 2 ? "fas" : "far"
                    } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                    onClick={() =>
                      this.setState({
                        questionPaperRating: 2,
                        highlightBadEmoji: null,
                        highlightGoodEmoji: null,
                        highlightNeutralEmoji: "highlight-emoji",
                      })
                    }
                  ></i>
                </div>
                <div id="Good" className="emoji-scale">
                  <span className="describeEmoji">Yes, a Lot</span>
                  <i
                    className={`emoji ${
                      this.state.questionPaperRating == 3 ? "fas" : "far"
                    } fa-grin-alt highlight ${this.state.highlightGoodEmoji}`}
                    onClick={() =>
                      this.setState({
                        questionPaperRating: 3,
                        highlightBadEmoji: null,
                        highlightNeutralEmoji: null,
                        highlightGoodEmoji: "highlight-emoji",
                      })
                    }
                  ></i>
                </div>
              </div>
              <TextField
                id="outlined-basic"
                placeholder="How could we make this better for you?"
                variant="outlined"
                fullWidth
                value={this.state.ratingComment}
                onChange={this.handleRatingComment}
                style={{ width: "90%", marginLeft: "5%" }}
              ></TextField>
            </div>
            <button
              type="submit"
              className="btn--submit"
              onClick={this.handleQPRating}
            >
              Submit
            </button>
          </div>
        )}
        {/* <SweetAlert
        customClass="sweet-alert-rating"
          show={this.state.showRatingAlert}
          showConfirm
          showCloseButton
          style={{ zIndex: 99999999 }}
          title={
            <>
              <div className="swal-titile">
                <h4>
                  <span>Your Question Paper is being Generated.</span>
                  <br />
                  <span>
                    Meanwhile, what do you think of your recent Question Paper?{" "}
                  </span>
                </h4>
              </div>
              <div className="rate--question large-star1 mt-4">
                <span className="feedbackLabel">
                  <span id="labelFeedbackLabel"></span>
                </span>
                <div className="line-height-0 d-flex justify-content-center pt-4">
                  <div id="bad" className="emoji-scale">
                    <span className="describeEmoji">Bad</span>
                    <i
                      className={`emoji ${this.state.questionPaperRating == 1 ? "fas" : "far"
                        } fa-frown highlight ${this.state.highlightBadEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 1,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: null,
                          highlightBadEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="neutral" className="emoji-scale">
                    <span className="describeEmoji">Neutral</span>
                    <i
                      className={`emoji ${this.state.questionPaperRating == 2 ? "fas" : "far"
                        } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 2,
                          highlightBadEmoji: null,
                          highlightGoodEmoji: null,
                          highlightNeutralEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="Good" className="emoji-scale">
                    <span className="describeEmoji">Good</span>
                    <i
                      className={`emoji ${this.state.questionPaperRating == 3 ? "fas" : "far"
                        } fa-grin-alt highlight ${this.state.highlightGoodEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 3,
                          highlightBadEmoji: null,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                </div>
              </div>
            </>
          }
          confirmBtnText="Submit"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={this.handleRatingPromptCloseEvent}
          onOutsideClick={this.handleRatingPromptCloseEvent}
          onConfirm={this.handleQPRating}
          onCancel={this.handleRatingPromptCloseEvent}
        ></SweetAlert> */}

        <SweetAlert
          customClass={classNames(
            styles["swal-preview"],
            "sweet-alert-preview"
          )}
          show={this.state.showPreview}
          showConfirm
          confirmBtnText="Okay"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showPreview: false })}
          onOutsideClick={() => this.setState({ showPreview: false })}
          onConfirm={() => this.setState({ showPreview: false })}
          onCancel={() => this.setState({ showPreview: false })}
        >
          <button
            type="button"
            className={classNames(
              styles["swal-cross-btn"],
              "close sets-standard-close"
            )}
            aria-label="Close"
            style={{ outline: "none" }}
            onClick={() => this.setState({ showPreview: false })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <QuestionPaperPreview
            location={{
              state: {
                topic: this.state.currentGeneratedQnTitle,
                content_id: this.state.questions[0]?.content_id,
                ques_count: this.state.ques_count,
              },
            }}
            isModalPreview={true}
          />
        </SweetAlert>
        <SweetAlert
          show={this.state.isVisualsPop}
          showConfirm
          showCancel
          showCloseButton
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="cancel"
          title="Please Read Carefully"
          confirmBtnText={`Yes, Proceed`}
          cancelBtnText={`Let me modify content`}
          onConfirm={this.handleVisualsProcess}
          onEscapeKey={() => this.setState({ isVisualsPop: false })}
          // onOutsideClick={() => this.setState({ isVisualsPop: false })}
          onCancel={() => this.setState({ isVisualsPop: false })}
        >
          <div
            className="swal-title"
            style={{
              textAlign: "center",
              padding: "0 30px",
              fontWeight: "400",
            }}
          >
            <p>
              Your content does not qualify for visual question generation as it
              doesn't belong to STEM{" "}
              <div className={classNames(styles["notify-tool-tip"])}>
                <i
                  style={{
                    marginTop: "3px",
                    marginLeft: "10px",
                    fontSize: "1.2rem",
                  }}
                  className="fas fa-info-circle instructions"
                ></i>
                <span className={classNames(styles["hoverTextVisualsPop"])}>
                  Science, Technology, Engineering, Mathematics related contents
                  only
                </span>
              </div>
              <br />
              <span>Would you like to proceed without visuals?</span>
            </p>
          </div>
        </SweetAlert>

        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showAlert}
          showConfirm
          title={
            <div className="swal-title">
              <h2>Please Read Carefully</h2>
              <hr />
              <ol>
                <li className="instruction-points">
                  PrepAI only supports content in the English language.
                </li>
                <li className="instruction-points">
                  PrepAI is best suited for subjective kind of content for now.
                </li>
                <li className="instruction-points">
                  PrepAI doesn't consider mathematical equations, chemical
                  reactions, numerical, tabular data, diagrams yet for question
                  generation.
                </li>
              </ol>
            </div>
          }
          confirmBtnText="OK"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showAlert: false })}
          onOutsideClick={() => this.setState({ showAlert: false })}
          onConfirm={() => this.setState({ showAlert: false })}
          onCancel={() => this.setState({ showAlert: false })}
        />
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showPremiumPrompt}
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">{this.state.premiumPromptText}</p>
              <Link className="btn prompt-plan-btn" to="/plans">
                Upgrade Plan
              </Link>
              {/* <span style={{ margin: "20px", fontSize: "20px" }}>OR</span>
              <Link className="btn prompt-referral-btn" to="/refer-prepai">
                Refer PrepAI
              </Link> */}
            </div>
          }
          onEscapeKey={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onOutsideClick={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onConfirm={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onCancel={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
        />
        <SweetAlert
          customClass="sweet-alert"
          show={window.innerWidth > 767 && this.state.showUpdateProfilePopUp}
          showConfirm
          showCancel
          title="Please complete your profile to continue"
          confirmBtnText="Finish"
          cancelBtnText="Ask Me Later"
          cancelBtnStyle={{ fontSize: "16px" }}
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showUpdateProfilePopUp: false })}
          onOutsideClick={() =>
            this.setState({ showUpdateProfilePopUp: false })
          }
          onConfirm={this.UpdateProfileSubmit}
          onCancel={this.CancelUpdateProfile}
          style={{ overflow: "initial", marginTop: "150px" }}
        >
          <form className="complete-profile mt-4">
            {/* {this.props.user.update_profile_index == 1 && ( */}
            <div className="row my-2">
              <label className="form--label col-6">
                What best describes your role?*
              </label>

              <div className="select-list col-6" style={{ fontSize: "larger" }}>
                <select
                  name="profession"
                  className="source_from custom-select"
                  style={{ width: "75%", height: "35px" }}
                  required
                  value={this.state.profession}
                  onChange={this.handleUpdateProfileChange}
                >
                  <option value="">Select</option>
                  {profession().map((op) => {
                    return (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {
              this.state.profession === "Others" && (
                // this.props.user.update_profile_index == 1 && (
                <>
                  {/* <hr className="mb-2" style={{width: "15%"}}/> */}
                  <div className="row  my-2">
                    <label
                      htmlFor="profession_other"
                      className="form--label col-6"
                    >
                      Please Specify*
                    </label>
                    <div className="col-6">
                      <input
                        type="text"
                        name="profession_other"
                        id="profession_other"
                        className="form-control"
                        value={this.state.profession_other}
                        onChange={this.handleChange}
                        required
                        style={{ width: "75%", height: "35px" }}
                      />
                    </div>
                  </div>
                </>
              )
              // )
            }
            {/* )}{" "} */}
            {/* {this.props.user.update_profile_index == 2 && ( */}
            {/* <hr className="my-2" style={{ width: "15%" }} /> */}
            <div className="row  my-2">
              <label className="form--label col-6">
                How did you discover PrepAI?*
              </label>
              <div className="select-list col-6" style={{ fontSize: "larger" }}>
                <select
                  name="source_from"
                  className="source_from custom-select"
                  style={{ width: "75%", height: "35px" }}
                  required
                  onChange={this.handleUpdateProfileChange}
                  value={this.state.source_from}
                  disabled={this.state.referralActive}
                >
                  <option value="">Select</option>

                  {findUsList().map((op) => {
                    return (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {
              this.state.source_from === "Others" && (
                // this.props.user.update_profile_index == 2 && (
                <>
                  {/* <hr className="mb-2" style={{width: "15%"}}/> */}
                  <div className="row my-2">
                    <label
                      htmlFor="source_from_other"
                      className="form--label col-6"
                    >
                      Please Specify*
                    </label>
                    <div className="col-6">
                      <input
                        type="text"
                        name="source_from_other"
                        id="source_from_other"
                        className="form-control"
                        value={this.state.source_from_other}
                        onChange={this.handleChange}
                        required
                        style={{ width: "75%", height: "35px" }}
                      />
                    </div>
                  </div>
                </>
              )
              // )
            }
            {/* )} */}
            {/* {this.props.user.update_profile_index == 3 && ( */}
            {/* <hr className="my-2" style={{ width: "15%" }} /> */}
            <div className="row my-2">
              <label className="form--label col-6">
                Update your contact number*
              </label>
              <div
                className="position-relative col-6"
                style={{ fontSize: "larger" }}
              >
                <PhoneInput
                  country={this.state.data.country_code}
                  countryCodeEditable={false}
                  placeholder="Enter phone number"
                  name="formatted_phone_number"
                  value={this.state.data.formatted_phone_number}
                  onChange={this.handleOnChange}
                  buttonStyle={{ backgroundColor: "transparent" }}
                  style={{ width: "75%" }}
                  className="complete-profile-popup"
                />
              </div>
            </div>
            {/* )} */}
            <div
              style={{
                position: "relative",
                marginLeft: "98px",
                marginTop: "18px",
              }}
            ></div>
          </form>
          {this.state.showLoader ? (
            <div className="spinner-border mt-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showEditContentPopUp}
          showConfirm={false}
          title={
            <div className="swal-title">
              <h4>Continue with sample text?</h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <div>
                  <button
                    type="button"
                    className="btn pp-btn"
                    onClick={this.handleGenerateAnyway}
                  >
                    Yes, generate anyway
                  </button>
                </div>
                <div
                  style={{
                    paddingLeft: window.innerWidth < 767 ? "20px" : "100px",
                  }}
                >
                  <button
                    type="button"
                    className="btn ppn-btn"
                    onClick={this.handleUpdateContent}
                  >
                    Enter your own text
                  </button>
                </div>
              </div>
            </div>
          }
          // confirmBtnText="OK"
          // confirmBtnStyle={{ fontSize: "16px" }}
          // confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showEditContentPopUp: false })}
          onOutsideClick={() => this.setState({ showEditContentPopUp: false })}
          onConfirm={() => this.setState({ showEditContentPopUp: false })}
          onCancel={() => this.setState({ showEditContentPopUp: false })}
        />

        {/* For the case when less than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.lessQuestionsGenerated}
          showCloseButton
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">
                {" "}
                Only {this.state.totalQuestionsFormed} questions generated. Add
                more content to generate the required number of questions.{" "}
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ lessQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ lessQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ lessQuestionsGenerated: false })}
          onCancel={() => this.setState({ lessQuestionsGenerated: false })}
        />

        {/* For the case when more than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.moreQuestionsGenerated}
          showConfirm={false}
          showCancel
          title={
            <div className="swal-title">
              <p className="style-text">
                {" "}
                Question generated are more than requested{" "}
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ moreQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ moreQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ moreQuestionsGenerated: false })}
          onCancel={() => this.setState({ moreQuestionsGenerated: false })}
        />

        <SweetAlert
          show={this.state.showAddOn}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          // title="Want this feature?"
          confirmBtnText={`Proceed`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showAddOn: false })}
          onOutsideClick={() => this.setState({ showAddOn: false })}
          onCancel={() => this.setState({ showAddOn: false })}
        >
          <div style={{ padding: "20px" }}>
            <h4>Upgrade to access Premium add ons</h4>
            <div className="login--form">
              <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: this.state.addOnText }}
              ></div>

              <div>
                <button
                  onClick={() => this.displayStripe(this.state.activatePlan)}
                  type="button"
                  role="button"
                  className="preview--paper--btn"
                  style={{
                    width: "max-content",
                  }}
                  disabled={this.state.showLoader}
                >
                  {this.state.addOnCTAText}
                </button>
              </div>
              {/* {this.state.activatePlan.plan_name === "Free" && (
                <Link to="/plans" className="ff-tdd">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.freeAddOnSubText,
                    }}
                  ></span>
                </Link>
              )} */}
              {this.state.showLoader ? (
                <div className="spinner-border mt-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </SweetAlert>

        <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          showConfirm={false}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert>

        {/* <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert> */}

        <TakeAssessment
          content_id={this.state.currentContentId}
          topic_name={this.state.currentTopicName}
          show={this.state.showDurationAlert}
          callBack={this.callbackFunc}
          history={this.props.history}
        />

        <ReferPopup />
        {!_.isEmpty(this.props.user.plan_details) &&
          window.heap.addUserProperties({
            subscription_type: this.props.user.plan_details.subscription_name,
          })}

        {this.state.showTopUp && (
          <QuestionPaperTopUp
            data={
              this.props.addOnsList["Question-Paper"][
                this.props.user.plan_details.plan_name
              ]
            }
            action={this.showTopUp}
            callBack={this.questionPaperTopUPCallBack}
          ></QuestionPaperTopUp>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    addOnsList: state.auth.addOnsList,
    showPopup: state.generalActions.showPopUp,
    defaultContent: state.generalActions.defaultContent,
    customQuesType: state.generalActions,
    selectedPurPlan: state.generalActions.plan,
  };
};

export default connect(mapStateToProps)(QuestionPaperGenerate);
