import * as React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { connect } from "react-redux";
import styles from "../../cssmodule/stepper.module.css";
import "../../styles/stepper.css";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(73,192,160,1) 35%, rgba(0,212,255,1) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(73,192,160,1) 35%, rgba(0,212,255,1) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(90deg, rgba(197,197,199,1) 0%, rgba(73,192,160,1) 26%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(90deg, rgba(197,197,199,1) 0%, rgba(73,192,160,1) 26%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <EmojiEventsIcon />,
    2: <EmojiEventsIcon />,
    3: <EmojiEventsIcon />,
    4: <EmojiEventsIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

// let steps = [
// 	"Create First Quiz",
// 	"Download Or Conduct First Quiz",
// 	"Complete Profile",
// ];

let steps = [
  { name: "Create First Quiz", value: 0 },
  { name: "Complete Profile", value: 2 },
  { name: "Download Or Conduct First Quiz", value: 1 },
];

class CustomizedSteppers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleText: "You are only 4 steps away from a surprise deal",
      steps: steps,
      currentMilestone: false,
      showCloseButton: false,
      stepsCompleted: props.steps,
      display: false,
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    this.checkPath(location.pathname);

    this.setState(
      {
        stepsCompleted: this.props.steps,
        currentMilestone: this.props.steps.length - 1,
      },
      () => {
        this.rearrangeSteps();
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        stepsCompleted: nextProps.steps,
        currentMilestone: nextProps.steps.length - 1,
      },
      () => {
        this.rearrangeSteps();
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.checkPath(location.pathname);
    }

    if (prevProps.steps !== this.props.steps) {
      // Handle updates to the "steps" prop here
      this.setState(
        {
          stepsCompleted: this.props.steps,
          currentMilestone: this.props.steps.length - 1,
        },
        () => {
          this.rearrangeSteps();
        }
      );
    }
  }

  handleNavigation() {
    this.props.history.push("/coupons-and-offers");
  }

  checkPath = (path) => {
    if (path === "/coupons-and-offers") {
      this.setState({ display: true });
    } else {
      this.setState({ display: false });
    }
  };

  // According to completed steps if completed not in regular fashion.
  rearrangeSteps = () => {
    let message = "You are only 3 steps away from a surprise deal";
    // this is to handle old users who have step 1 completed in case
    // Replace step 1 with step 3 in stepsCompleted array and ensure uniqueness
    let stepsCompletedSet = new Set(
      this.state.stepsCompleted.map((stepValue) => {
        let mappedValue = stepValue;
        if (stepValue === 1 || stepValue === 3) {
          mappedValue = 1;
        }
        const matchingStep = steps.find((step) => step.value === mappedValue);
        return matchingStep ? matchingStep.name : null; // Map step value to step name
      })
    );
    let stepsCompleted = Array.from(stepsCompletedSet);
    let countCompletedSteps = stepsCompleted.length;

    let newSteps = stepsCompleted.map((stepName) => {
      const matchingStep = steps.find((step) => step.name === stepName);
      return matchingStep ? matchingStep.value : null; // Map step name back to step value
    });

    if (countCompletedSteps < 1) {
      newSteps = steps.map((step) => step.name);
    } else if (countCompletedSteps === 1) {
      // Append remaining steps to newSteps
      const remainingValues = steps
        .filter((step) => !newSteps.includes(step.value))
        .map((step) => step.name);
      newSteps = [...newSteps, ...remainingValues];
      message = "You are only 2 steps away from a surprise deal";
    } else if (countCompletedSteps === 2) {
      message = "You are amazing! just 1 more step to go";
      const remainingValues = steps
        .filter((step) => !newSteps.includes(step.value))
        .map((step) => step.name);
      newSteps = [...newSteps, ...remainingValues];
    } else if (countCompletedSteps === 3) {
      message = "New coupon unlocked, Get 20% discount on Solo plans";
    }
    newSteps = newSteps.map((step) => {
      if (typeof step === "number") {
        const matchingStep = steps.find((s) => s.value === step);
        return matchingStep ? matchingStep.name : step;
      } else {
        return step;
      }
    });
    this.setState({
      titleText: message,
      currentMilestone: countCompletedSteps - 1,
      steps: newSteps,
    });
  };

  render() {
    return (
      <>
        {this.state.display ? (
          <>
            <Stack sx={{ width: "100%" }} spacing={4}>
              <h6 className={styles["stepper-title"]}>
                {this.state.titleText}
              </h6>
              <Stepper
                alternativeLabel
                activeStep={this.state.currentMilestone}
                connector={<ColorlibConnector />}
              >
                {this.state.steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label.name ? label.name : label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </>
        ) : (
          <>
            {this.state.stepsCompleted.length <= 2 && (
              <div className="stepper-container">
                <div className="arrow-steps clearfix">
                  {this.state.steps.map((step, index) => (
                    <div
                      className={`step ${
                        index <= this.state.currentMilestone ? "current" : ""
                      }`}
                      key={index}
                    >
                      <span className="text">
                        <a className="step-tag">{`step ${index + 1}`}</a>
                        <br />
                        <a>{step.name ? step.name : step}</a>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(CustomizedSteppers));
